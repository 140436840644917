import React from 'react'
import {observer} from 'mobx-react'

import {formatAccountNumberWithDots} from './services'
import {AccountNumberViewProps} from './props'
import styles from './AccountNumberView.scss'

export const AccountNumberView: React.FC<AccountNumberViewProps> = observer(({accountNumber}) => {
    if (!accountNumber) return null

    const {firstPart, secondPart} = formatAccountNumberWithDots(accountNumber?.split(' ')[0])

    return (
        <div className={styles.maskedNumber}>
            <span className={styles.dots}></span> <span>{firstPart}&nbsp;</span>{' '}
            <span>{secondPart}</span>
        </div>
    )
})
