import React from 'react'
import {observer} from 'mobx-react'

import {withTitle} from '@/router/utils'
import {OutletsContainer} from '@/components'

import translations from './translations'

export const POSOutletsStoreSymbol = Symbol('POSOutletsStoreSymbol')

const POSOutlets: React.FC<null> = observer(() => {
    return (
        <OutletsContainer
            injectableIdentifier={POSOutletsStoreSymbol}
            containerTitle={translations().posOutlets}
        />
    )
})

export default withTitle(POSOutlets, translations().posOutlets)
