import React from 'react'
import {Button, ButtonProps} from 'antd'
import {MessageOutlined} from '@ant-design/icons'
import classNames from 'classnames'

import {useScreenType} from '@/hooks'

import styles from './ChatButton.scss'

export const ChatButton: React.FC<ButtonProps> = ({onClick}) => {
    const {isMobile} = useScreenType()

    return (
        <Button
            type='primary'
            onClick={onClick}
            icon={<MessageOutlined />}
            className={classNames(styles.button, {[styles.buttonMobile]: isMobile})}
        />
    )
}
