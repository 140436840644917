import translations from './translations'

translations.add('sl', {
    companyProfile: 'Profil Spoločnosti',
    labels: {
        customerId: 'ID Zákazníka',
        entityType: 'Typ Subjektu',
        registeredNumber: 'Registračné Číslo',
        legalName: 'Právny Názov',
        registeredAddress: 'Registrovaná Adresa',
        tradingName: 'Obchodný Názov',
        tradingAddress: 'Obchodná Adresa',
        companyWebsite: 'Webová Stránka Spoločnosti',
        companyEmailAddress: 'Emailová Adresa Spoločnosti',
        companyPhoneNumber: 'Telefón Spoločnosti'
    }
})
