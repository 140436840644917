import translations from './translations'

translations.add('sl', {
    labels: {
        bank: 'Banka',
        sortCode: 'Kód Pobočky',
        accountNumber: 'Číslo Účtu',
        associatedSettlementsOutlets: 'Pridružené Prevádzky Vyrovnania'
    }
})
