import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    labels: {
        bank: string
        sortCode: string
        accountNumber: string
        associatedSettlementsOutlets: string
    }
}>()
