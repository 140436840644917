import React from 'react'
import {observer} from 'mobx-react'
import {Avatar, Card as AntCard, Descriptions} from 'antd'
import {RightOutlined} from '@ant-design/icons'

import {useScreenType} from '@/hooks'
import {Card} from '@/components'

import {AccountNumberView, AccountTypeView} from '@/pages/BankAccounts/components'

import translations from './translations'
import {BankAccountCardProps} from './props'
import styles from './BankAccountCard.scss'

export const BankAccountCard: React.FC<BankAccountCardProps> = observer(
    ({
        bank,
        accountName,
        accountNumber,
        associatedSettlementsOutlets,
        accountTypes,
        sortCode,
        logo,
        onClick
    }) => {
        const {isMobile} = useScreenType()

        return (
            <Card hoverable={!!onClick} className={styles.card} onClick={onClick && onClick}>
                {isMobile && <RightOutlined className={styles.rightIcon} />}
                <AntCard.Meta
                    avatar={<Avatar className={styles.avatar} src={logo} />}
                    title={accountName}
                    description={
                        <>
                            <Descriptions
                                className={styles.descriptionLabel}
                                title=''
                                layout={'vertical'}
                                column={1}
                            >
                                {bank && (
                                    <Descriptions.Item label={translations().labels.bank}>
                                        {bank}
                                    </Descriptions.Item>
                                )}
                                <Descriptions.Item label={translations().labels.sortCode}>
                                    {sortCode}
                                </Descriptions.Item>
                                <Descriptions.Item label={translations().labels.accountNumber}>
                                    <AccountNumberView accountNumber={accountNumber} />
                                </Descriptions.Item>
                                {associatedSettlementsOutlets && (
                                    <Descriptions.Item
                                        label={translations().labels.associatedSettlementsOutlets}
                                    >
                                        {associatedSettlementsOutlets
                                            .map((outlet) => outlet.trim())
                                            .join(', ')}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                            {accountTypes && <AccountTypeView accountTypes={accountTypes} />}
                        </>
                    }
                />
            </Card>
        )
    }
)
