import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {ROUTES} from './routes'

export const routesPermissionMap = {
    [ROUTES.home]: [PermissionsMap.overview.read],
    [ROUTES.activation]: [],
    [ROUTES.paymentLinks]: [PermissionsMap.payment_links.read, PermissionsMap.payment_links.full],
    [ROUTES.onlinePayments]: [
        PermissionsMap.online_payments.read,
        PermissionsMap.online_payments.full
    ],
    [ROUTES.chargebacks]: [PermissionsMap.chargebacks.read],
    [ROUTES.posPayments]: [PermissionsMap.pos_payments.read, PermissionsMap.pos_payments.full],
    [ROUTES.posAmexPayments]: [
        PermissionsMap.pos_amex_payments.read,
        PermissionsMap.pos_amex_payments.full
    ],
    [ROUTES.settlements]: [PermissionsMap.settlements.read, PermissionsMap.settlements.full],
    [ROUTES.virtualTerminal]: [PermissionsMap.virtual_terminal.full],
    [ROUTES.reports]: [PermissionsMap.reports.read],
    [ROUTES.exports]: [
        PermissionsMap.pos_payments.read,
        PermissionsMap.pos_payments.full,
        PermissionsMap.pos_amex_payments.read,
        PermissionsMap.pos_amex_payments.full,
        PermissionsMap.online_payments.read,
        PermissionsMap.online_payments.full,
        PermissionsMap.settlements.full
    ],
    [ROUTES.profile]: [],
    [ROUTES.settings.developers]: [],
    [ROUTES.settings.checkout]: [],
    [ROUTES.settings.tariffs]: [],
    [ROUTES.settings.companyProfile]: [
        PermissionsMap.merchant.profile.read,
        PermissionsMap.merchant.profile.full
    ],
    [ROUTES.settings.bankAccounts]: [
        PermissionsMap.merchant.bank_accounts.read,
        PermissionsMap.merchant.bank_accounts.full
    ],
    [ROUTES.settings.base]: [
        PermissionsMap.merchant.bank_accounts.read,
        PermissionsMap.merchant.bank_accounts.full,
        PermissionsMap.merchant.bank_accounts.read,
        PermissionsMap.merchant.bank_accounts.full,
        PermissionsMap.merchant.ecom_stores.read,
        PermissionsMap.merchant.ecom_stores.full,
        PermissionsMap.merchant.pos_stores.read,
        PermissionsMap.merchant.pos_stores.full
    ],
    [ROUTES.teamManagement]: [PermissionsMap.teammates.read, PermissionsMap.teammates.full],
    [ROUTES.businessAccount.accountInfo]: [
        PermissionsMap.accountInfo.read,
        PermissionsMap.accountInfo.full
    ],
    [ROUTES.businessAccount.cardsManagement]: [
        PermissionsMap.cardsManagement.read,
        PermissionsMap.cardsManagement.full
    ],
    [ROUTES.paymentMethods]: [
        PermissionsMap.payment_methods.read,
        PermissionsMap.payment_methods.full
    ],
    [ROUTES.paymentMethodDetails]: [
        PermissionsMap.payment_methods.read,
        PermissionsMap.payment_methods.full
    ],
    [ROUTES.billing.invoices]: [PermissionsMap.invoices.read, PermissionsMap.invoices.full],
    [ROUTES.noPermissions]: []
}
