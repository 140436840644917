import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    labels: {
        address: string
        mid: string
    }
    status: {
        active: string
        closed: string
    }
}>()
