import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import {TransactionType} from '@/types/transactions'
import {POSPaymentStateStatus} from '@/constants/pos-payment-state-status'
import {OnlinePaymentStatus} from '@/constants/online-payment-status'
import {ChargebackStatus} from '@/constants/chargeback-status'
import {PaymentCardType} from '@/constants/payment-card-type'
import {ResonCodeCategory} from '@/constants/reason-code-category'
import {CardSchemeType} from '@/constants/card-scheme'
import translations from './translations'

translations.add('el', {
    siteTitle: 'Merchant Portal DNA Payments',
    pages: {
        home: 'Αρχική σελίδα',
        activation: 'Ενεργοποίηση',
        overview: 'ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ',
        paymentLinks: 'Σύνδεσμοι για πληρωμή',
        posPayments: 'Πληρωμές POS',
        posAmexPayments: 'POS American Express',
        onlinePayments: 'Διαδικτυακές πληρωμές',
        chargebacks: 'Αντιστροφές χρεώσεων',
        virtualTerminal: 'Εικονικό τερματικό',
        settlements: 'Διακανονισμοί',
        billing: 'Χρέωση',
        invoices: 'Τιμολόγια',
        reports: 'Αναφορές',
        paymentMethods: 'Μέθοδοι πληρωμής',
        payPal: 'PayPal',
        payByBank: 'Pay by Bank',
        openBanking: 'Open Banking',
        settings: 'Ρυθμίσεις',
        developers: 'Προγραμματιστές',
        checkoutCustomization: 'Προσαρμογή checkout',
        tariffs: 'Tariffs',
        profile: 'Προφίλ',
        login: 'Σύνδεση',
        resetPassword: 'Επαναφέρετε τον κωδικό πρόσβασης',
        twoFA: 'Διπλή Πιστοποίηση Ταυτότητας',
        twoFAEnforced: 'Έχει επιβληθεί ο έλεγχος ταυτότητας δύο παραγόντων',
        recoveryCode: 'Recovery code',
        chooseCompany: 'Choose Company',
        confirmEmail: 'Επιβεβαίωση Email',
        confirmTwoFA: 'Confirm two-factor authentication',
        cardsManagement: 'Διαχείριση καρτών',
        setPassword: 'Ορίστε κωδικό',
        teamManagement: 'Διαχείριση ομάδας',
        noPermissions: 'Χωρίς Άδειες',
        help: 'Βοήθεια',
        ecospendReturn: 'Επιστροφή Ecospend',
        noPage: 'Χωρίς σελίδα',
        accountInfo: 'Πληροφορίες λογαριασμού',
        exports: 'Εξαγωγές',
        makeTransfer: 'Make transfer',
        companyProfile: 'Εταιρικό Προφίλ',
        bankAccounts: 'Τραπεζικοί λογαριασμοί',
        posOutlets: 'Καταστήματα POS',
        onlineOutlets: 'Διαδικτυακά Καταστήματα'
    },
    constants: {
        onlinePaymentStatus: {
            [OnlinePaymentStatus.all]: 'Ολα',
            [OnlinePaymentStatus.auth]: 'Εκκρεμή',
            [OnlinePaymentStatus.created]: 'Δημιουργήθηκε',
            [OnlinePaymentStatus.credited]: 'Πιστώθηκε',
            [OnlinePaymentStatus.charge]: 'Χρεώθηκε',
            [OnlinePaymentStatus.cancel]: 'Ακυρώθηκε',
            [OnlinePaymentStatus.refund]: 'Επιστράφηκε',
            [OnlinePaymentStatus.reject]: 'Απορρίφθηκε',
            [OnlinePaymentStatus.failed]: 'Απέτυχε',
            [OnlinePaymentStatus.new]: 'Νέο',
            [OnlinePaymentStatus.threeDSecure]: '3D Secure',
            [OnlinePaymentStatus.tokenized]: 'Tokenized',
            [OnlinePaymentStatus.verified]: 'Επαληθεύτηκε',
            [OnlinePaymentStatus.processing]: 'Σε Επεξεργασία',
            [OnlinePaymentStatus.abandoned]: 'Εγκαταλείφθηκε',
            [OnlinePaymentStatus.other]: 'Άλλα'
        },
        chargebackStatus: {
            [ChargebackStatus.all]: 'Όλα',
            [ChargebackStatus.chargeback]: 'Επιστροφή χρημάτων',
            [ChargebackStatus['chargeback-reversal']]: 'Αντιστροφή επιστροφής χρημάτων',
            [ChargebackStatus['second-chargeback-lost']]: 'Δεύτερη επιστροφή χρημάτων - Χαμένη'
        },
        reasonCodeCategory: {
            [ResonCodeCategory['processing-errors']]: 'Σφάλματα επεξεργασίας',
            [ResonCodeCategory['fraud']]: 'Απάτη',
            [ResonCodeCategory['consumer']]: 'Καταναλωτής',
            [ResonCodeCategory['authorisation']]: 'Εξουσιοδότηση'
        },
        posPaymentStatus: {
            all: 'Ολα',
            authorised: 'Εξουσιοδοτημένο',
            cancelled: 'Ακυρώθηκε',
            charged: 'Χρεώθηκε',
            declined: 'Απορρίφθηκε',
            refunded: 'Επιστράφηκε',
            chargeback: 'Επιστροφή χρεώσεως',
            'chargeback-reversal': 'Αντιστροφή της αντιστροφής χρεώσεως',
            'second-chargeback-lost': 'Δεύτερο αντιστροφή χρεώσεως - χαμένο'
        },
        posPaymentStateStatus: {
            [POSPaymentStateStatus.all]: 'Ολα',
            [POSPaymentStateStatus.successful]: 'Επιτυχής',
            [POSPaymentStateStatus.failed]: 'Απορρίφθηκε'
        },
        payByLinkStatus: {
            active: 'Ενεργοί',
            expired: 'Εχει λήξει',
            paid: 'Πληρώθηκε',
            cancelled: 'Ακυρώθηκε',
            viewed: 'Προβολή',
            attempted: 'Προσπάθεια',
            all: 'Ολα'
        },
        paymentMethodType: {
            card: 'Κάρτα',
            paypal: 'PayPal',
            applepay: 'Apple Pay',
            googlepay: 'Google Pay',
            ecospend: 'Open Banking',
            paybybankapp: 'Pay by Bank',
            klarna: 'Klarna',
            clicktopay: 'ClickToPay',
            astropay: 'AstroPay',
            alipay: 'Alipay',
            wechatpay: 'WeChat Pay'
        },
        fileType: {
            PDF: 'PDF',
            EXCEL: 'EXCEL',
            CSV: 'CSV'
        },
        issuerRegion: {
            Domestic: 'Εσωτερικός',
            Intra: 'Εντός',
            International: 'Διεθνής'
        },
        cardType: {
            [PaymentCardType.Credit]: 'Πίστωση',
            [PaymentCardType.Debit]: 'Πίστωση'
        },
        cardScheme: {
            [CardSchemeType.Amex]: 'American Express',
            [CardSchemeType.Diners]: 'Diners Club',
            [CardSchemeType.Mastercard]: 'MasterCard',
            [CardSchemeType.UnionPay]: 'UnionPay',
            [CardSchemeType.Visa]: 'Visa'
        },
        captureMethods: {
            'ecom-paybybankapp': 'Pay by Bank app',
            'ecom-openbanking': 'Open Banking',
            'ecom-klarna': 'Klarna',
            'pos-contactless': 'Aνέπαφο POS',
            'pos-openbanking': 'Open Banking',
            'pos-alipay': 'Alipay',
            ecom: 'ECOM',
            pos: 'POS',
            stored: 'Stored',
            moto: 'MOTO',
            recurring: 'Επαναλαμβανόμενο'
        },
        terminalTypes: {
            all: 'Ολα',
            ecom: 'ECOM',
            pos: 'POS'
        }
    },
    filter: {
        all: 'Ολα',
        allAll: 'Ολα/Ολα'
    },
    success: {
        paymentChargedSuccessfully: 'Η πληρωμή χρεώθηκε με επιτυχία',
        paymentRefundedSuccessfully: 'Η μερική επιστροφή χρημάτων της πληρωμής ήταν επιτυχής',
        paymentRefundedFullySuccessfully: 'Η πλήρης επιστροφή χρημάτων της πληρωμής ήταν επιτυχής',
        newPaymentProcessedSuccessfully: 'Η διαδικασία νέας πληρωμής ήταν επιτυχής',
        sendReceiptSuccess: 'Η απόδειξη εστάλη με επιτυχία.',
        downloadReceiptSuccess: 'Η λήψη της απόδειξης έγινε με επιτυχία.'
    },
    errors: {
        refundProcessing: 'Η συναλλαγή επιστροφής χρημάτων βρίσκεται υπό επεξεργασία.',
        unknown: 'Ωχ, κάτι πήγε στραβά. Η ομάδα μας ήδη το εξετάζει.',
        errorOccured: 'Εμφανίστηκε κάποιο σφάλμα. Παρακαλώ δοκιμάστε αργότερα.',
        general:
            'Λυπούμαστε, δεν μπορούμε να επεξεργαστούμε το αίτημα αυτήν τη στιγμή. Παρακαλώ δοκιμάστε ξανά αργότερα.',
        maxAttempt: (count) => `Επιτεύχθηκαν το μέγιστο ${count} προσπάθειες.`,
        INVALID_CARD_NUMBER: 'Μη έγκυρος αριθμός κάρτας',
        INVALID_CARDHOLDER_NAME: 'Μη έγκυρο όνομα καρτοκάτοχου',
        INVALID_SECURE_CODE: 'Μη έγκυρος κωδικός ασφαλείας',
        INVALID_EXPIRY_DATE: 'Μη έγκυρη ημερομηνία λήξης'
    },
    transactionTypes: {
        [TransactionType.REFUND]: 'Επιστροφή χρημάτων',
        [TransactionType.ADJUSTMENT]: 'Διόρθωση',
        [TransactionType.OTHER]: 'Άλλα',
        [TransactionType.PAYBYLINK]: 'Pay by Link',
        [TransactionType.RETAIL]: 'Πώληση',
        [TransactionType.SALE]: 'Πώληση',
        [TransactionType.INIT]: 'Init',
        [TransactionType.VERIFICATION]: 'Επαλήθευση',
        [TransactionType.TOKENIZATION]: 'Tokenization',
        [TransactionType.P2P]: 'P2P',
        [TransactionType.RECURRING]: 'Επαναλαμβανόμενο',
        [TransactionType.AUTH]: 'Μόνο εξουσιοδότηση',
        [TransactionType.PREAUTH as any]: 'Προέγκριση'
    },
    transactionTypesTooltip: {
        [TransactionType.SALE]: '“Πώληση“ - η συναλλαγή θα εγκριθεί και θα διευθετηθεί αυτόματα',
        [TransactionType.AUTH]:
            '“Μόνο εξουσιοδότηση” - δέσμευση χρημάτων με περαιτέρω χειροκίνητο διακανονισμό (Λειτουργία χρέωσης)',
        [TransactionType.VERIFICATION]: '“Μόνο επαλήθευση” - μόνο επαλήθευση λογαριασμού',
        [TransactionType.PREAUTH as any]:
            '“Προέγκριση” - προέγκριση χρημάτων με περαιτέρω χειροκίνητο διακανονισμό (Λειτουργία Χρέωσης)'
    },
    languages: {
        english: 'English',
        spanish: 'Español',
        greek: 'Ελληνικά',
        romanian: 'Română',
        slovak: 'Slovenský'
    },
    action: {
        title: 'Ενέργεια',
        viewDetails: 'Δείτε λεπτομέρειες'
    },
    fields: {
        accountNumber: 'Αριθμός λογαριασμού',
        cardMask: 'Μάσκα κάρτας',
        cardScheme: 'Τύπος κάρτας',
        captureMethod: 'Μέθοδος λήψης',
        currency: 'Νόμισμα',
        mid: 'MID',
        store: 'Κατάστημα',
        status: 'Κατάσταση',
        terminalId: 'Αναγνωριστικό τερματικού',
        terminalType: 'Τύπος τερματικού',
        transactionId: 'Αναγνωριστικό συναλλαγής'
    },
    messages: {
        successExportMessage: (
            <span>
                Το αίτημα λήψης σας έχει μπει σε ουρά για επεξεργασία. Μόλις το έγγραφο είναι
                έτοιμο, μπορείτε να το κατεβάσετε από την ενότητα{' '}
                <Link to={ROUTES.exports}>Εξαγωγές</Link>.
            </span>
        ),
        validation: {
            minLength: (n) => `Εισαγάγετε τουλάχιστον ${n} χαρακτήρες`
        }
    }
})
