import React, {useEffect, useState} from 'react'
import {Grid, Layout} from 'antd'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {IdleTimer} from 'dna-common'
import {useInjection} from 'dna-react-ioc'
import {useLocation} from 'react-router-dom'

import {Theme} from '@/constants'
import {useAuthStore} from '@/stores'
import {IDLE_TIME_OUT_TIME} from '@/constants'
import {ErrorBoundary, SendbirdChatContainer, UserGuideContainer} from '@/components'
import {useStorageNotification} from '@/hooks'
import {computeOpenKeys, computeSelectedKey} from '@/services/menu'
import {Burger, Header, Sider} from '@/components/dumb/layout'

import {useAuthNotificationStore} from '@/stores/notification'
import {NotificationDisplayTypeEnum, NotificationStatusEnum} from '@/stores/notification/types'
import {
    BannerNotification,
    BlockNotification,
    PopupNotification
} from '@/components/composite/notifications'
import {MobileAppStoresRedirect} from '@/components/composite/MobileAppStoresRedirect/MobileAppStoresRedirect'

import {IMainLayoutStore} from './IMainLayoutStore'
import {MainLayoutProps} from './props'
import styles from './styles.scss'

const {Content} = Layout
const {useBreakpoint} = Grid

export const MainLayoutStoreSymbol = Symbol('mainLayoutStore')
export const NotificationStoreSymbol = Symbol('NotificationStoreSymbol')
export const AuthNotificationStoreSymbol = Symbol('AuthNotificationStoreSymbol')

export const MainLayout = observer(({children, theme}: MainLayoutProps) => {
    const store = useInjection<IMainLayoutStore>(MainLayoutStoreSymbol)
    const authStore = useAuthStore()

    const screens = useBreakpoint()
    const isMobile = screens.hasOwnProperty('md') && !screens.md
    const currentRoute = window.location.pathname
    const openKeys = computeOpenKeys(currentRoute, store.menuItems)
    const currentUserEmail = authStore.email

    const {sections: sectionsNotifications} = useAuthNotificationStore()

    // get current section notification based on the current route
    // and check if it is already closed in the local storage
    const currentSectionNotification = Object.values(sectionsNotifications).find(
        (section) => section.route === currentRoute
    )
    const {isClosed: isSectionNotificationClosed, onCloseNotification: onCloseSectionNotification} =
        useStorageNotification(currentSectionNotification, 'auth', currentUserEmail)

    // get popup notification
    // and check if it is already closed in the local storage
    const popupNotification = sectionsNotifications['auth']
    const {isClosed: isPopupNotificationClosed, onCloseNotification: onClosePopupNotification} =
        useStorageNotification(popupNotification, 'auth', currentUserEmail)

    const [selectedKey, setSelectedKey] = useState(
        computeSelectedKey(currentRoute, store.menuItems)
    )

    useEffect(() => {
        // Hide menu in mobile device
        isMobile && store.setCollapsed(isMobile)
    }, [isMobile])

    useEffect(() => {
        setSelectedKey(computeSelectedKey(currentRoute, store.menuItems))
    }, [useLocation().pathname])

    const onCancelSession = () => {
        console.log('CANCELLING SESSION')
        authStore.logout(false, {
            trigger: 'session-expiry'
        })
    }

    const HeaderMobile = () => (
        <Header isMobile={true}>
            <Burger onClick={() => store.setCollapsed(false)} />
        </Header>
    )

    return (
        <Layout className={classNames(styles.layout, {mobile: isMobile})}>
            <IdleTimer onCancelSession={onCancelSession} timeOut={IDLE_TIME_OUT_TIME} />
            {store.merchant?.merchantId && (_IS_PROD_ || _IS_TEST_) && <SendbirdChatContainer />}
            <Sider
                isMobile={isMobile}
                isCollapsed={store.isCollapsed}
                setCollapsed={store.setCollapsed}
                menuItems={store.menuItems}
                openKeys={openKeys ?? []}
                selectedKeys={selectedKey ? [selectedKey] : []}
            />
            <Layout className={classNames({[styles.layoutLight]: theme === Theme.LIGHT})}>
                <MobileAppStoresRedirect />

                {isMobile ? <HeaderMobile /> : <Header />}
                {currentSectionNotification &&
                currentSectionNotification.notificationDisplayTypeId ===
                    NotificationDisplayTypeEnum.BANNER &&
                !isSectionNotificationClosed ? (
                    <div className={styles.bannerNotificationContainer}>
                        <BannerNotification
                            notification={currentSectionNotification}
                            onCloseNotification={onCloseSectionNotification}
                        />
                    </div>
                ) : null}
                <Content>
                    {currentSectionNotification &&
                    currentSectionNotification.notificationDisplayTypeId ===
                        NotificationDisplayTypeEnum.BLOCK &&
                    currentSectionNotification.statusId === NotificationStatusEnum.ACTIVE ? (
                        <BlockNotification notification={currentSectionNotification} />
                    ) : (
                        <ErrorBoundary>{children}</ErrorBoundary>
                    )}
                </Content>
                {popupNotification && !isPopupNotificationClosed && (
                    <PopupNotification
                        notification={popupNotification}
                        onCloseNotification={onClosePopupNotification}
                    />
                )}
            </Layout>

            <UserGuideContainer />
        </Layout>
    )
})
