import {inject, injectable} from 'inversify'
import {computed, makeObservable} from 'mobx'

import {RouterStore, RouterStoreSymbol} from '@/router/RouterStore'
import {ICompanyProfileStore} from '@/pages/CompanyProfile'
import {
    DossierMerchantCompanyInfo,
    DossierMerchantInfoStore,
    DossierMerchantInfoStoreSymbol
} from '@/stores/profile/DossierMerchantInfo'

@injectable()
export class CompanyProfileStore implements ICompanyProfileStore {
    routerStore: RouterStore
    dossierMerchantInfoStore: DossierMerchantInfoStore

    public constructor(
        @inject(RouterStoreSymbol) routerStore: RouterStore,
        @inject(DossierMerchantInfoStoreSymbol)
        dossierMerchantInfoStore: DossierMerchantInfoStore
    ) {
        this.routerStore = routerStore
        this.dossierMerchantInfoStore = dossierMerchantInfoStore

        makeObservable(this, {
            companyInfo: computed
        })
    }

    get companyInfo(): DossierMerchantCompanyInfo {
        return this.dossierMerchantInfoStore?.dossierMerchantData?.mainInfo
    }
}
