import React from 'react'
import {observer} from 'mobx-react'
import {useParams} from 'react-router-dom'

import {withTitle} from '@/router/utils'
import {OutletDetailsContainer} from '@/components'

import translations from './translations'

export const POSOutletDetailsStoreSymbol = Symbol('POSOutletDetailsStoreSymbol')

const POSOutletDetails: React.FC<null> = observer(() => {
    const {id} = useParams<{id: string}>()

    return (
        <OutletDetailsContainer
            id={id}
            injectableIdentifier={POSOutletDetailsStoreSymbol}
            containerTitle={translations().posOutlets}
        />
    )
})

export default withTitle(POSOutletDetails, translations().posOutlets)
