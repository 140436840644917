import translations from './translations'

translations.add('el', {
    labels: {
        address: 'Διεύθυνση',
        mid: 'MID'
    },
    status: {
        active: 'Ενεργό',
        closed: 'Κλειστό'
    }
})
