import translations from './translations'

translations.add('ro', {
    titles: {
        selfBankAccount: 'Cont bancar propriu',
        directDebitAccount: 'Cont de debit direct',
        settlementsAccount: 'Cont de decontări'
    },
    labels: {
        outletStatus: 'Stare Punct de Vânzare',
        address: 'Adresă',
        mid: 'MID'
    }
})
