import {register} from 'dna-react-ioc'
import {ISettingsStore} from '@/pages/Settings'
import {SettingsStore} from '@/stores/settings'
import {SettingsStoreSymbol} from '@/pages/Settings/Settings'
import {CompanyProfileStoreSymbol} from '@/pages/CompanyProfile/CompanyProfile'
import {ICompanyProfileStore} from '@/pages/CompanyProfile'
import {CompanyProfileStore} from '@/stores/company-profile'
import {IBankAccountsStore} from '@/pages/BankAccounts'
import {BankAccountsStoreSymbol} from '@/pages/BankAccounts/BankAccounts'
import {BankAccountsStore} from '@/stores/bank-accounts'
import {POSOutletsStoreSymbol} from '@/pages/POSOutlets/POSOutlets'
import {OutletsStore} from '@/stores/outlets'
import {IOutletDetailsContainerStore, IOutletsContainerStore} from '@/components'
import {TerminalType} from '@/constants'
import {POSOutletDetailsStoreSymbol} from '@/pages/POSOutletDetails/POSOutletDetails'
import {OutletDetailsStore} from '@/stores/outlets/OutletDetailsStore'
import {OnlineOutletsStoreSymbol} from '@/pages/OnlineOutlets/OnlineOutlets'
import {OnlineOutletDetailsStoreSymbol} from '@/pages/OnlineOutletDetails/OnlineOutletDetails'
import {RouterStoreSymbol} from '@/router/RouterStore'

export const settingsState = () => {
    register<ISettingsStore>(SettingsStoreSymbol).to(SettingsStore).inSingletonScope()
    register<ICompanyProfileStore>(CompanyProfileStoreSymbol)
        .to(CompanyProfileStore)
        .inSingletonScope()
    register<IBankAccountsStore>(BankAccountsStoreSymbol).to(BankAccountsStore).inSingletonScope()

    register<IOutletsContainerStore>(POSOutletsStoreSymbol)
        .toDynamicValue(
            (context) =>
                new OutletsStore(TerminalType.POS, context.container.get(RouterStoreSymbol))
        )
        .inSingletonScope()

    register<IOutletDetailsContainerStore>(POSOutletDetailsStoreSymbol)
        .to(OutletDetailsStore)
        .inSingletonScope()

    register<IOutletsContainerStore>(OnlineOutletsStoreSymbol)
        .toDynamicValue(
            (context) =>
                new OutletsStore(TerminalType.ECOM, context.container.get(RouterStoreSymbol))
        )
        .inSingletonScope()

    register<IOutletDetailsContainerStore>(OnlineOutletDetailsStoreSymbol)
        .to(OutletDetailsStore)
        .inSingletonScope()
}
