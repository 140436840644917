import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Popover} from 'antd'
import '@sendbird/uikit-react/dist/index.css'

import {ChatButton, ChatContent} from './components'
import {ISendbirdChatStore} from './ISendbirdChatStore'
import {SendbirdChatProps} from './props'
import styles from './SendbirdChatContainer.scss'

export const SendbirdChatContainerStoreSymbol = Symbol('SendbirdChatContainerStore')

export const SendbirdChatContainer: React.FC<SendbirdChatProps> = observer(() => {
    const store = useInjection<ISendbirdChatStore>(SendbirdChatContainerStoreSymbol)

    useEffect(() => {
        const initChat = async () => {
            try {
                await store.initializeSendbirdChat()
            } catch (error) {
                console.error('Failed to initialize chat:', error)
            }
        }

        initChat()
        return () => {
            store.dispose()
        }
    }, [store])

    const handleToggleChat = () => store.setOpen(!store.open)

    return (
        <div className={styles.container}>
            <Popover
                open={store.open}
                placement='topRight'
                trigger='click'
                getPopupContainer={(triggerNode) => triggerNode.parentElement as HTMLElement}
                content={<ChatContent store={store} />}
            >
                <ChatButton onClick={handleToggleChat} />
            </Popover>
        </div>
    )
})
