import translations from './translations'

translations.add('ro', {
    labels: {
        address: 'Adresă',
        mid: 'MID'
    },
    status: {
        active: 'Activ',
        closed: 'Închis'
    }
})
