import React from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {Map, Marker, TileLayer} from 'react-leaflet'

import styles from './GeolocationMap.scss'

interface GeolocationPaneProps {
    center: {
        lat: number
        lng: number
    }
    zoom?: number
    className?: string
}

export const GeolocationMap = observer(({center, className, zoom = 11}: GeolocationPaneProps) => {
    return (
        <div className={classNames(styles.geolocationMap, className)}>
            <Map center={center} zoom={zoom}>
                <TileLayer
                    attribution={
                        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    }
                    url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
                />
                <Marker position={center} />
            </Map>
        </div>
    )
})
