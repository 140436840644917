import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    companyProfile: string
    labels: {
        customerId: string
        entityType: string
        registeredNumber: string
        legalName: string
        registeredAddress: string
        tradingName: string
        tradingAddress: string
        companyWebsite: string
        companyEmailAddress: string
        companyPhoneNumber: string
    }
}>()
