import translations from './translations'

translations.add('es', {
    labels: {
        bank: 'Banco',
        sortCode: 'Código de Clasificación',
        accountNumber: 'Número de Cuenta',
        associatedSettlementsOutlets: 'Establecimientos de Liquidación Asociados'
    }
})
