import translations from './translations'

translations.add('ro', {
    labels: {
        bank: 'Bancă',
        sortCode: 'Cod Sortare',
        accountNumber: 'Număr de Cont',
        associatedSettlementsOutlets: 'Puncte de Decont Asociate'
    }
})
