import React from 'react'
import {observer} from 'mobx-react'
import {MinusOutlined} from '@ant-design/icons'
import {GroupChannel} from '@sendbird/uikit-react/GroupChannel'
import {GroupChannelHeader} from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader'

import {SendbirdPanel} from '@/stores'

import {HeaderIconButton} from '../HeaderIconButton/HeaderIconButton'

import {ChannelComponentProps} from './props'
import styles from './ChannelComponent.scss'

export const ChannelComponent: React.FC<ChannelComponentProps> = observer(({store}) => {
    const {setPanel, currentChannelUrl, setOpen} = store

    return (
        <GroupChannel
            onBackClick={() => setPanel(SendbirdPanel.CHANNEL_LIST)}
            replyType={'NONE'}
            channelUrl={currentChannelUrl}
            disableUserProfile
            isReactionEnabled={false}
            isMessageGroupingEnabled={false}
            renderChannelHeader={() => (
                <GroupChannelHeader
                    renderLeft={() => (
                        <HeaderIconButton
                            type={'ARROW_LEFT'}
                            onClick={() => setPanel(SendbirdPanel.CHANNEL_LIST)}
                        />
                    )}
                    renderRight={() => (
                        <HeaderIconButton
                            type={'LEAVE'}
                            className={styles.hideIcon}
                            onClick={() => setOpen(false)}
                            customIcon={<MinusOutlined className={styles.hideIcon} />}
                        />
                    )}
                />
            )}
        />
    )
})
