import {makeAutoObservable, reaction} from 'mobx'
import {inject, injectable} from 'inversify'
import {
    CardSettingsStoreInterface,
    CardsManagementStoreInterface
} from '@/pages/business-account/CardsManagement'
import {CardSettingsStoreSymbol} from '@/pages/business-account/CardsManagement/components/CardSettings/CardSettings'
import {
    activateCard,
    AuthStoreInterface,
    AuthStoreSymbol,
    CardIssuingConfigStoreInterface,
    CardModel,
    CardsRequest,
    CardsResponse,
    fetchCardFirstHalf,
    fetchCards,
    formatCard
} from '@/stores'
import {openErrorNotification} from '@/utils'
import {LoadingState} from '@/types'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'
import {CardIssuingConfigsStoreSymbol} from '@/pages/business-account/AccountInfo/AccountInfo'

@injectable()
export class CardsManagementStore implements CardsManagementStoreInterface {
    private _cardSettingsStore: CardSettingsStoreInterface
    private _authStore: AuthStoreInterface
    private _profileStore: ProfileStoreInterface
    private readonly _cardIssuingConfigStore: CardIssuingConfigStoreInterface

    constructor(
        @inject(CardSettingsStoreSymbol) cardSettingsStore: CardSettingsStoreInterface,
        @inject(AuthStoreSymbol) authStore: AuthStoreInterface,
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface,
        @inject(CardIssuingConfigsStoreSymbol)
        cardIssuingConfigStore: CardIssuingConfigStoreInterface
    ) {
        this._cardSettingsStore = cardSettingsStore
        this._authStore = authStore
        this._profileStore = profileStore
        this._cardIssuingConfigStore = cardIssuingConfigStore

        makeAutoObservable(this)

        reaction(
            () => this._cardIssuingConfigStore.tokens,
            async () => {
                await this.loadCards()
            }
        )

        reaction(
            () => this.currentCardToken,
            async () => {
                this._cardSettingsStore.setCardToken(this.currentCardToken)
            }
        )
    }

    _cards: CardModel[] = null
    cardsLoadingState: LoadingState = LoadingState.IDLE
    currentCardToken = null

    get card(): CardModel {
        return this._cards?.find(card => card.token === this.currentCardToken)
    }

    get cards(): CardModel[] {
        if (
            this._cardIssuingConfigStore.tokens.merchant &&
            this.cardsLoadingState === LoadingState.IDLE
        ) {
            this.loadCards()
        }

        return this._cards
    }

    setCurrentCardToken = (currentCardToken: string) => {
        this.currentCardToken = currentCardToken
    }

    loadCardsFirstHalf = (result: CardsResponse) => {
        try {
            const requests = result.data?.items.map(
                async item => await fetchCardFirstHalf(item.token)
            )

            Promise.all(requests)
                .then(responses => {
                    this._cards = responses.map(({result: firstHalfResult}, index) => ({
                        number: formatCard(firstHalfResult.data?.pan),
                        date: `${firstHalfResult.data.expirationMonth}/${firstHalfResult.data.expirationYear}`,
                        name: this._profileStore.merchant?.name?.toUpperCase(),
                        ...result?.data?.items[index]
                    }))

                    this.cardsLoadingState = LoadingState.DONE
                })
                .catch(() => {
                    this.cardsLoadingState = LoadingState.FAILED
                })
        } catch (error) {
            this.cardsLoadingState = LoadingState.FAILED
        }
    }

    loadCards = async () => {
        if (this.cardsLoadingState === LoadingState.LOADING) {
            return
        }

        try {
            this.cardsLoadingState = LoadingState.LOADING

            const request: Partial<CardsRequest> = {
                merchant: this._cardIssuingConfigStore.tokens.merchant
            }

            const {result, error} = await fetchCards(request)

            if (error) {
                this.cardsLoadingState = LoadingState.FAILED
                throw new Error(error.message)
            }

            if (result) {
                this.loadCardsFirstHalf(result)
                this.currentCardToken = result?.data?.items && result.data.items[0]?.token
            }
        } catch (e) {
            openErrorNotification(e)
            this.cardsLoadingState = LoadingState.FAILED
        }
    }

    activateCard = async () => {
        try {
            const {result, error} = await activateCard()

            if (error) {
                throw new Error(error.message)
            }

            if (result) {
            }
        } catch (e) {
            openErrorNotification(e)
        }
    }
}
