import React from 'react'
import Header from '@sendbird/uikit-react/ui/Header'

import {HeaderIcon} from '../HeaderIcon'

import {HeaderIconButtonProps} from './props'

export const HeaderIconButton: React.FC<HeaderIconButtonProps> = ({
    type,
    onClick,
    className,
    customIcon
}) => (
    <Header.IconButton
        type={type}
        color='CONTENT'
        className={className}
        renderIcon={(props) => customIcon || <HeaderIcon {...props} />}
        onClick={onClick}
    />
)
