import translations from './translations'

translations.add('en', {
    companyProfile: 'Company profile',
    labels: {
        customerId: 'Customer ID',
        entityType: 'Entity Type',
        registeredNumber: 'Registered number',
        legalName: 'Legal name',
        registeredAddress: 'Registered address',
        tradingName: 'Trading name',
        tradingAddress: 'Trading address',
        companyWebsite: 'Company website',
        companyEmailAddress: 'Company email address',
        companyPhoneNumber: 'Company phone number'
    }
})
