export const ROUTES = {
    home: '/',
    maintenance: '/maintenance',
    activation: '/activation',
    paymentLinks: '/payment-links',
    onlinePayments: '/online-payments',
    chargebacks: '/chargebacks',
    posPayments: '/pos-payments',
    posAmexPayments: '/pos-amex-payments',
    settlements: '/settlements',
    billing: {
        base: '/billing',
        invoices: '/billing/invoices'
    },
    virtualTerminal: '/virtual-terminal',
    reports: '/reports',
    exports: '/exports',
    profile: '/profile',
    paymentMethods: '/payment-methods',
    paymentMethodDetails: '/payment-methods/detail/:type',
    businessAccount: {
        accountInfo: '/business-account/account-info',
        cardsManagement: '/business-account/cards-management',
        makeTransfer: '/business-account/transfer'
    },
    settings: {
        base: '/settings',
        developers: '/settings/developers',
        checkout: '/settings/checkout',
        tariffs: '/settings/tariffs',
        companyProfile: '/settings/company-profile',
        bankAccounts: '/settings/bank-accounts',
        posOutlets: '/settings/pos-outlets',
        onlineOutlets: '/settings/online-outlets'
    },
    teamManagement: '/team-management',
    login: '/login',
    resetPassword: '/reset-password',
    twoFA: '/two-factor-authentication',
    recoveryCode: '/recovery-code',
    chooseCompany: '/choose-company',
    resetPasswordFailure: '/reset-password-failure',
    confirmEmail: '/confirm-email',
    confirmTwoFA: '/confirm-2fa',
    twoFAEnforced: '/enforce-2fa',
    setPassword: '/set-password',
    setPasswordSuccess: '/set-password-success',
    noPermissions: '/no-permissions',
    ecospendReturn: '/ecospend-return',
    noPage: '/no-page'
}
