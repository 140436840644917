import React from 'react'
import {observer} from 'mobx-react'
import {Status} from '@/components'

import {getAccountTypeLabel, getAccountTypeTagType} from './services'
import {AccountTypeViewProps} from './props'
import styles from './AccountTypeView.scss'

export const AccountTypeView: React.FC<AccountTypeViewProps> = observer(({accountTypes}) => {
    return (
        <>
            {accountTypes
                .filter((type) => getAccountTypeLabel(type))
                .map((type) => (
                    <Status
                        key={type}
                        className={styles.tag}
                        status={getAccountTypeLabel(type)}
                        type={getAccountTypeTagType(type)}
                        iconType={'check'}
                    />
                ))}
        </>
    )
})
