import 'reflect-metadata'
import uuid from 'uuid/v4'
import {inject, injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction} from 'mobx'
import {ColumnsType} from 'antd/es/table'
import {getColumns} from './services/ui-utils'
import {openErrorNotification} from '@/utils'
import {getChargebacks, ChargebackType} from '@/api'
import {FilterType} from '@/stores/chargebacks/ChargebacksFilterStore'
import {TChargebacksStore, ChargebacksStoreSymbol} from '@/pages/Chargebacks'
import {PaginationContainerStoreType} from '@/components/containers'
import {
    TChargebacksTableStore,
    TChargebacksDetailsStore,
    ChargebacksDetailsStoreSymbol,
    ChargebacksPaginationStoreSymbol
} from '@/pages/Chargebacks/components'

@injectable()
export class ChargebacksTableStore implements TChargebacksTableStore {
    data: ChargebackType[] = []
    isLoading: boolean = false
    chargebacksStore: TChargebacksStore
    chargebacksDetailsStore: TChargebacksDetailsStore
    paginationStore: PaginationContainerStoreType

    constructor(
        @inject(ChargebacksStoreSymbol) chargebacksStore: TChargebacksStore,
        @inject(ChargebacksDetailsStoreSymbol) chargebacksDetailsStore: TChargebacksDetailsStore,
        @inject(ChargebacksPaginationStoreSymbol) paginationStore: PaginationContainerStoreType
    ) {
        this.paginationStore = paginationStore
        this.chargebacksStore = chargebacksStore
        this.chargebacksDetailsStore = chargebacksDetailsStore

        makeObservable(this, {
            data: observable,
            isLoading: observable,

            filters: computed,

            setData: action.bound,
            setIsLoading: action.bound
        })

        reaction(
            () => ({
                selectedFilters: this.chargebacksStore.selectedFilters
            }),
            async () => {
                if (this.paging.page === 1) {
                    await this.loadChargebacks()
                } else {
                    this.paginationStore.setPageNumber(1)
                }
            }
        )

        reaction(
            () => ({
                pageNumber: this.paginationStore.pageNumber
            }),
            async () => await this.loadChargebacks()
        )
    }

    get paging() {
        return this.paginationStore?.paging
    }

    get filters(): FilterType {
        return {...this.chargebacksStore.selectedFilters, ...this.paginationStore.paging}
    }

    get columns(): ColumnsType<ChargebackType> {
        return getColumns()
    }

    initialize = async () => {
        await this.loadChargebacks()
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading
    }

    setData = (data: ChargebackType[]) => {
        this.data = data.map((item) => ({
            ...item,
            uniqueKey: uuid()
        }))
    }

    loadChargebacks = async (): Promise<void> => {
        this.setIsLoading(true)

        try {
            const {result, error} = await getChargebacks(this.filters)

            if (error) {
                return openErrorNotification(error.message)
            }

            this.setData(result?.data || [])
            this.paginationStore.setTotal(result?.totalCount || 0)
        } catch (error) {
            openErrorNotification(error)
        } finally {
            this.setIsLoading(false)
        }
    }

    reload = async () => {
        await this.loadChargebacks()
    }
}
