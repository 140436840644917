import translations from './translations'

translations.add('sl', {
    columnHeader: {
        business: 'Podnikanie',
        outletsNProducts: 'Predajne & Produkty',
        other: 'Ostatné'
    },
    cardTitle: {
        companyProfile: 'Profil Spoločnosti',
        bankAccounts: 'Bankové Účty',
        posOutlets: 'Predajne POS',
        onlineOutlets: 'Online Predajne',
        teamManagement: 'Správa Tímu'
    },
    cardDescription: {
        companyProfile: 'Údaje vašej spoločnosti',
        bankAccounts: 'Priamy debet a zúčtovanie',
        posOutlets: 'Kamenné obchody',
        onlineOutlets: 'Internetové obchody',
        teamManagement: 'Prístupové pozvánky a role'
    }
})
