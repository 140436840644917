import React from 'react'
import {Timeline} from 'antd'
import {getAmountWithCurrency, isEmptyOrSpaces} from '@/utils'
import {Currencies, ChargebackStatus} from '@/constants'
import {ChargebackInfoType, ChargebackType} from '@/api'
import {CardSchemeLabelWithIcon, IconTitle, TableStatus} from '@/components'
import {TagType} from '@/components/dumb/Tag/props'

import rootTranslations from '@/translations'
import translations from '../translations'
import styles from '../styles.scss'

export const buildTransactionFields = (
    transaction: ChargebackInfoType,
    chargeback: ChargebackType
) => {
    if (!transaction) return []

    return [
        {label: translations().bankingDate, value: transaction.date, key: 'date'},
        {
            label: translations().amount,
            value: transaction.amount,
            key: 'amount',
            render: () =>
                getAmountWithCurrency(transaction.amount, transaction.currency as Currencies)
        },
        {
            label: translations().captureMethod,
            value: chargeback.captureMethod ? String(chargeback.captureMethod).toUpperCase() : '',
            key: 'captureMethod'
        },
        {
            label: translations().transactionType,
            value: transaction.transactionType,
            key: 'transactionType'
        },
        {
            label: translations().cardScheme,
            value: chargeback.cardScheme,
            key: 'cardScheme',
            render: () => (
                <CardSchemeLabelWithIcon cardScheme={chargeback.cardScheme} showCardSchemeTitle />
            )
        },
        {
            label: translations().cardMask,
            value: chargeback.cardMask,
            key: 'cardMask'
        },
        {label: translations().documentId, value: transaction.documentId, key: 'documentId'},
        {label: translations().terminalId, value: chargeback.terminalId, key: 'terminalId'},
        {label: translations().transactionId, value: chargeback.transactionId, key: 'transactionId'}
    ]
}

export const buildChargebackFields = (chargeback: ChargebackType) => {
    if (!chargeback) return []

    return [
        {
            label: translations().chargebackDate,
            value: chargeback.chargebackDate,
            key: 'chargebackDate'
        },
        {
            label: translations().amount,
            value: chargeback.amount,
            key: 'amount',
            render: () =>
                getAmountWithCurrency(chargeback.amount, chargeback.currency as Currencies)
        },
        {
            label: translations().reasonCode,
            value: chargeback.reasonCode,
            key: 'reasonCode',
            render: () => (
                <IconTitle
                    className={styles.iconTitle}
                    title={chargeback.reasonCode}
                    tooltipText={chargeback.reasonCodeDescription}
                />
            )
        },
        {label: translations().arn, value: chargeback.arn, key: 'arn'}
    ]
}

export const buildChargebackHistoryFields = (chargebackHistory: ChargebackInfoType[]) =>
    chargebackHistory?.map(({documentId, date, chargebackStatus, amount, currency, status}) => ({
        key: documentId,
        render: () => (
            <Timeline.Item key={documentId} label={`${date}`} className={styles.timelineItem}>
                {[
                    {
                        title: translations().chargebackStatus,

                        value: !isEmptyOrSpaces(chargebackStatus) ? (
                            <div className={styles.statusTagHolder}>
                                <TableStatus
                                    statusProps={{
                                        status:
                                            rootTranslations().constants.chargebackStatus[
                                                chargebackStatus
                                            ] || chargebackStatus,
                                        type:
                                            chargebackStatus ===
                                            ChargebackStatus['chargeback-reversal']
                                                ? TagType.Success
                                                : TagType.Default,
                                        iconType: 'refund'
                                    }}
                                />
                            </div>
                        ) : null
                    },
                    {
                        title: translations().amount,
                        value: getAmountWithCurrency(amount, currency as Currencies)
                    },
                    {
                        title: translations().financialStatus,
                        value: status
                    },
                    {title: translations().documentId, value: documentId}
                ]?.map(({title, value}, index) => (
                    <div key={index} className={styles.timelineItemRow}>
                        <div className={styles.timelineItemTitle}>{title}</div>
                        <div className={styles.timelineItemValue}>{value}</div>
                    </div>
                ))}
            </Timeline.Item>
        )
    }))
