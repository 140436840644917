import React from 'react'
import { Button, Checkbox, Col, Form, Modal, Row, Select } from 'antd'
import {observer} from 'mobx-react'
import { useInjection } from 'dna-react-ioc'

import { IExportsModalContainerStore } from './store'
import { ExportsModalContainerProps } from './props'
import translations from './translations'
import {
    ADD_COLUMN_TO_EXPORT,
    CANCEL_EXPORT,
    COLUMNS_TYPE_TO_EXPORT,
    CUSTOM_FIELDS_TO_EXPORT,
    SUBMIT_EXPORT
} from '@/constants/playwright-ids'

const { useForm } = Form

export const ExportsModalContainer = observer((props: ExportsModalContainerProps) => {
    const {
        title = translations().ok,
        injectableIdentifier,
        store: _store
    } = props

    const store = _store || useInjection<IExportsModalContainerStore>(injectableIdentifier)

    const {
        open,
        isLoading,
        fieldOptions,
        setOpen,
        submit
    } = store

    const [form] = useForm()

    const allFields = Object.keys(fieldOptions)

    const getSelectedFields = () => (
        form.getFieldValue('columnView') === 'custom'
            ? form.getFieldValue('fields') || []:
            allFields
    )

    const columnViewOptions = [
        {value: 'all', label: translations().all(allFields.length)},
        {value: 'custom', label: translations().custom}
    ]

    const onCancel = () => {
        form.resetFields()
        setOpen(false)
    }

    const onSubmit = async () => {
        const fields = getSelectedFields()
        if (fields.length > 0 && await submit(fields)) {
            onCancel()
        }
    }

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            title={title}
            footer={
                <Row justify={'space-between'} style={{width: '100%'}}>
                    <Col>
                        <Button onClick={onCancel} data-test-id={CANCEL_EXPORT}>
                            {translations().cancel}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            loading={isLoading}
                            disabled={isLoading}
                            type='primary'
                            onClick={form.submit}
                            data-test-id={SUBMIT_EXPORT}
                        >
                            {translations().ok}
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Form
                form={form}
                initialValues={{
                    columnView: 'all',
                    fields: []
                }}
                onFinish={onSubmit}
            >
                <Form.Item
                    label={translations().columns}
                    name={'columnView'}
                    shouldUpdate
                    data-test-id={COLUMNS_TYPE_TO_EXPORT}
                >
                    <Select
                        options={columnViewOptions}
                    />
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                    {() => (
                        form.getFieldValue('columnView') === 'custom' ? (
                            <Form.Item name={'fields'} data-test-id={CUSTOM_FIELDS_TO_EXPORT}>
                                <Checkbox.Group>
                                    <Row>
                                        {Object.entries(fieldOptions).map(([value, label]) => (
                                            <Col span={8} key={value}>
                                                <Checkbox value={value} data-test-id={ADD_COLUMN_TO_EXPORT + value}>
                                                    {label}
                                                </Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        ) : null
                    )}
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                    {() => {
                        const labels = getSelectedFields().map((field) => fieldOptions[field])
                        return (
                            <div>{labels.length ? labels.join(', ') : ''}</div>
                        )
                    }}
                </Form.Item>
            </Form>
        </Modal>
    )
})
