import React from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {Card as AntCard, Descriptions, Typography} from 'antd'
import {RightOutlined} from '@ant-design/icons'

import {useScreenType} from '@/hooks'
import {Card, GeolocationMap, OutletStatus} from '@/components'

import translations from './translations'
import {OutletCardProps} from './props'
import styles from './OutletCard.scss'

export const OutletCard: React.FC<OutletCardProps> = observer(
    ({onClick, mid, address, isActive, storeName, latitude, longitude}) => {
        const {isMobile} = useScreenType()

        return (
            <Card
                hoverable
                className={classNames(styles.card, {[styles.cardMobile]: isMobile})}
                onClick={onClick}
            >
                {isMobile && <RightOutlined className={styles.rightIcon} />}
                <AntCard.Meta
                    title={storeName}
                    description={
                        <>
                            <Descriptions
                                className={styles.descriptionLabel}
                                title=''
                                layout={'vertical'}
                                column={1}
                            >
                                {address && (
                                    <Descriptions.Item label={translations().labels.address}>
                                        {address}
                                    </Descriptions.Item>
                                )}
                                {mid && (
                                    <Descriptions.Item label={translations().labels.mid}>
                                        <Typography.Paragraph copyable>{mid}</Typography.Paragraph>
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                            {!isMobile && <br />}
                            <OutletStatus isActive={isActive} />
                        </>
                    }
                />
                {isMobile && <br />}
                {latitude && longitude && (
                    <GeolocationMap
                        center={{
                            lat: latitude,
                            lng: longitude
                        }}
                        className={classNames(styles.map, {[styles.mapMobile]: isMobile})}
                    />
                )}
            </Card>
        )
    }
)
