import {injectable} from 'inversify'
import {computed, makeObservable, observable, runInAction} from 'mobx'

import {LoadingState} from '@/types'
import {IOutletDetailsContainerStore} from '@/components'
import {fetchDossierMerchantOutletDetails, OutletDetailsResponseModel} from '@/stores/outlets'

@injectable()
export class OutletDetailsStore implements IOutletDetailsContainerStore {
    loadingState: LoadingState = LoadingState.IDLE
    _outletDetails: OutletDetailsResponseModel = null

    constructor() {
        makeObservable(this, {
            loadingState: observable,
            _outletDetails: observable,
            outletDetails: computed,
            isLoading: computed
        })
    }

    get outletDetails(): OutletDetailsResponseModel {
        return this._outletDetails
    }

    get isLoading(): boolean {
        return this.loadingState === LoadingState.LOADING
    }

    loadDossierMerchantOutletDetails = async (id: string) => {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => (this.loadingState = LoadingState.LOADING))

        try {
            const {result, error} = await fetchDossierMerchantOutletDetails(id)

            if (error) {
                runInAction(() => (this.loadingState = LoadingState.FAILED))
            } else {
                runInAction(() => {
                    this.loadingState = LoadingState.DONE
                    this._outletDetails = result
                })

                if (!result) return
            }
        } catch (error) {
            runInAction(() => (this.loadingState = LoadingState.FAILED))
            throw new Error(error.message)
        }
    }
}
