import React from 'react'
import {observer} from 'mobx-react'

import {Status} from '@/components'
import {TagType} from '@/components/dumb/Tag/props'

import translations from './translations'
import styles from './OutletStatus.scss'

export const OutletStatus: React.FC<{isActive: boolean}> = observer(({isActive}) => {
    return (
        <Status
            className={styles.status}
            status={isActive ? translations().status.active : translations().status.closed}
            type={isActive ? TagType.Success : TagType.Disabled}
            iconType={isActive ? 'check' : 'close'}
        />
    )
})
