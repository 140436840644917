import React from 'react'
import {Col, Row} from 'antd'
import {isEmpty} from 'dna-common'

import {getAmountWithCurrency} from '@/utils'
import {
    CardSchemeLabelWithIcon,
    DateTimeLabel,
    GeolocationMap,
    PaymentMethodOrMaskedCard,
    Status
} from '@/components'
import {OnlinePaymentStatus, PaymentMethodType, VerificationStatus} from '@/constants'
import {EpayPayment, SignifydDetails} from '@/api/transactions/models'
import {TagType} from '@/components/dumb/Tag/props'
import icons from '~/assets/icons'
import rootTranslations from '@/translations'
import translations from '@/pages/OnlinePayments/translations/translations'

export const getOnlinePaymentStatusName = (status: OnlinePaymentStatus): string => {
    const title = rootTranslations().constants.onlinePaymentStatus[status]
    return title || rootTranslations().constants.onlinePaymentStatus[OnlinePaymentStatus.other]
}

export const getOnlinePaymentStatusTagType = (status: OnlinePaymentStatus): TagType => {
    switch (status) {
        case OnlinePaymentStatus.failed:
        case OnlinePaymentStatus.reject:
            return TagType.Error
        case OnlinePaymentStatus.processing:
        case OnlinePaymentStatus.threeDSecure:
            return TagType.Processing
        case OnlinePaymentStatus.auth:
        case OnlinePaymentStatus.charge:
        case OnlinePaymentStatus.verified:
        case OnlinePaymentStatus.tokenized:
            return TagType.Success
        default:
            return TagType.Default
    }
}

export const getOnlinePaymentStatusIconType = (status: OnlinePaymentStatus): keyof typeof icons => {
    switch (status) {
        case OnlinePaymentStatus.failed:
        case OnlinePaymentStatus.reject:
        case OnlinePaymentStatus.cancel:
            return 'close'
        case OnlinePaymentStatus.processing:
        case OnlinePaymentStatus.auth:
        case OnlinePaymentStatus.created:
        case OnlinePaymentStatus.threeDSecure:
        case OnlinePaymentStatus.new:
            return 'clock'
        case OnlinePaymentStatus.charge:
        case OnlinePaymentStatus.verified:
        case OnlinePaymentStatus.tokenized:
            return 'tick'
        case OnlinePaymentStatus.refund:
            return 'restore'
        case OnlinePaymentStatus.credited:
            return 'arrow_right'
        case OnlinePaymentStatus.abandoned:
            return 'eye_closed'
        default:
            return 'info_help'
    }
}

export const getIconType = (type: TagType) => {
    switch (type) {
        case TagType.Success:
            return 'tick'
        case TagType.Warn:
            return 'warn'
        default:
            return 'close'
    }
}

export const getVerificationStatusTagType = (status: VerificationStatus): TagType => {
    switch (status) {
        case VerificationStatus.matched:
        case VerificationStatus.yes:
        case VerificationStatus.enrolled:
        case VerificationStatus.challengeRequired:
        case VerificationStatus.attempted:
        case VerificationStatus.authenticated:
            return TagType.Success
        case VerificationStatus.partialMatch:
            return TagType.Warn
        case VerificationStatus.notChecked:
        case VerificationStatus.notSet:
        case VerificationStatus.notAttempted:
            return TagType.Default
        default:
            return TagType.Error
    }
}

export const getPaEnrollmentStatus = (status: string): VerificationStatus => {
    switch (status) {
        case 'Y':
            return VerificationStatus.enrolled
        case 'C':
            return VerificationStatus.challengeRequired
        case 'A':
            return VerificationStatus.attempted
        case 'N':
            return VerificationStatus.notEnrolled
        case 'U':
            return VerificationStatus.unableToCheck
        case 'R':
            return VerificationStatus.authRejected
        default:
            return VerificationStatus.notAttempted
    }
}

export const getPaAuthStatus = (status: string): VerificationStatus => {
    switch (status) {
        case 'Y':
            return VerificationStatus.authenticated
        case 'A':
            return VerificationStatus.attempted
        case 'N':
            return VerificationStatus.notAuthenticated
        case 'U':
            return VerificationStatus.unableToAuth
        case 'R':
            return VerificationStatus.authRejected
        default:
            return VerificationStatus.notAttempted
    }
}

const renderVerificationStatus = (status: string) => {
    return (
        <Row>
            <Col>
                <Status
                    status={translations().statuses[status]}
                    type={getVerificationStatusTagType(status as VerificationStatus)}
                    iconType={getIconType(
                        getVerificationStatusTagType(status as VerificationStatus)
                    )}
                />
            </Col>
        </Row>
    )
}

export const getExportColumns = () => ({
    createdDate: {value: 'createdDate', label: translations().createdDate},
    store: {value: 'store', label: translations().shop},
    amount: {value: 'amount', label: translations().amount},
    payoutAmount: {value: 'payoutAmount', label: translations().payoutAmount},
    balance: {value: 'balance', label: translations().balance},
    currency: {value: 'currency', label: translations().currency},
    status: {value: 'status', label: translations().status},
    payoutDate: {value: 'payoutDate', label: translations().payoutDate},
    id: {value: 'id', label: translations().id},
    merchantReference: {value: 'merchantReference', label: translations().invoiceId},
    reference: {value: 'reference', label: translations().reference},
    transactionType: {value: 'transactionType', label: translations().transactionTypeLower},
    processingType: {value: 'processingType', label: translations().processingType},
    description: {value: 'description', label: translations().description},
    paymentMethod: {value: 'paymentMethod', label: translations().paymentMethod},
    issuer: {value: 'issuer', label: translations().issuer},
    cardScheme: {value: 'cardScheme', label: translations().cardType},
    cardMask: {value: 'cardMask', label: translations().cardMask},
    cardExpiryDate: {value: 'cardExpiryDate', label: translations().cardExpiryDate},
    secure3D: {value: 'secure3D', label: translations().secure3D},
    responseCode: {value: 'responseCode', label: translations().acquirerResponseCode},
    authCode: {value: 'authCode', label: translations().authCode},
    message: {value: 'message', label: translations().message},
    avsHouseNumberResult: {
        value: 'avsHouseNumberResult',
        label: translations().avsHouseNumberResult
    },
    avsPostcodeResult: {value: 'avsPostcodeResult', label: translations().avsPostcodeResult},
    cscResult: {value: 'cscResult', label: translations().cscResult},
    paEnrollment: {value: 'paEnrollment', label: translations().paEnrollment},
    paAuthentication: {value: 'paAuthentication', label: translations().paAuthentication},
    payerName: {value: 'payerName', label: translations().payerName},
    accountId: {value: 'accountId', label: translations().accountId},
    payerEmail: {value: 'payerEmail', label: translations().payerEmail},
    payerPhone: {value: 'payerPhone', label: translations().payerPhone},
    payerIp: {value: 'payerIp', label: translations().payerIp},
    ipCountry: {value: 'ipCountry', label: translations().ipCountry},
    ipCity: {value: 'ipCity', label: translations().ipCity},
    ipLatitude: {value: 'ipLatitude', label: translations().ipLatitude},
    ipLongitude: {value: 'ipLongitude', label: translations().ipLongitude},
    initiatorEmail: {value: 'initiatorEmail', label: translations().initiatorEmail}
})

const payerDetails = (selectedTransaction) => ({
    title: translations().payerDetails,
    fields: [
        {
            label: translations().payer,
            value: selectedTransaction.payerName
        },
        {
            label: translations().accountId,
            value: selectedTransaction.accountId
        },
        {
            label: translations().email,
            value: selectedTransaction.payerEmail
        },
        {
            label: translations().phone,
            value: selectedTransaction.payerPhone
        }
    ]
})

const paymentPageDetails = (selectedTransaction) => ({
    title: translations().paymentPage.title,
    fields: [
        {
            label: translations().paymentPage.language,
            value: selectedTransaction.language
        },
        {
            label: translations().paymentPage.postLinkAddress,
            value: selectedTransaction.postLink
        },
        {
            label: translations().paymentPage.postLink,
            value: selectedTransaction.postLinkStatus
                ? translations().paymentPage.ok
                : translations().paymentPage.failed
        }
    ]
})

const renderGeolocationText = (payment: EpayPayment) => {
    const {ipCity, ipCountry, ipRegion, ipDistrict, ipLatitude, ipLongitude} = payment

    let locationSpecification = [ipCountry, ipRegion, ipDistrict]
        .filter((item) => !!item)
        .join(', ')

    if (locationSpecification.length) {
        locationSpecification = '(' + locationSpecification + ')'
    }

    return (
        <div>
            <div>
                {ipCity} {locationSpecification}
            </div>
            {ipLatitude && ipLongitude ? (
                <a
                    href={`http://www.google.com/maps/place/${ipLatitude},${ipLongitude}`}
                    target='blank'
                >
                    {`N${ipLatitude}, E${ipLongitude}`}
                </a>
            ) : null}
        </div>
    )
}

const renderGeolocationMap = (payment: EpayPayment) => {
    const {ipLatitude, ipLongitude} = payment

    return ipLatitude && ipLongitude ? (
        <GeolocationMap
            center={{
                lat: ipLatitude,
                lng: ipLongitude
            }}
        />
    ) : null
}

const geolocationDetails = (selectedTransaction) => ({
    title: translations().geolocation.location,
    fields: [
        {
            label: translations().geolocation.payerIp,
            value: selectedTransaction.payerIp
        },
        {
            label: translations().description,
            value: selectedTransaction.ipLongitude,
            render: () => renderGeolocationText(selectedTransaction)
        },
        selectedTransaction.ipLatitude &&
            selectedTransaction.ipLongitude && {
                value: selectedTransaction.ipLatitude,
                render: () => renderGeolocationMap(selectedTransaction)
            }
    ].filter((item) => item)
})

const orderDetails = (selectedTransaction) => ({
    title: translations().paymentDetails,
    fields: [
        {
            label: translations().paymentMethod,

            render: () => {
                const {cardType, cardMask, paymentMethod} = selectedTransaction
                return (
                    <PaymentMethodOrMaskedCard
                        cardScheme={cardType}
                        cardMask={cardMask}
                        paymentMethod={paymentMethod}
                    />
                )
            }
        },
        {
            label: translations().amount,
            value: getAmountWithCurrency(selectedTransaction.amount, selectedTransaction.currency)
        },
        selectedTransaction.status === OnlinePaymentStatus.charge && {
            label: translations().chargedAmount,
            value: getAmountWithCurrency(
                selectedTransaction.payoutAmount,
                selectedTransaction.currency
            )
        },
        selectedTransaction.status === OnlinePaymentStatus.refund && {
            label: translations().refundAmount,
            value: getAmountWithCurrency(
                selectedTransaction.payoutAmount,
                selectedTransaction.currency
            )
        },
        selectedTransaction.status === OnlinePaymentStatus.charge && {
            label: translations().balance,
            value: getAmountWithCurrency(selectedTransaction.balance, selectedTransaction.currency)
        },
        {
            label: translations().status,
            value: getOnlinePaymentStatusName(selectedTransaction?.status),
            render: () => (
                <Row>
                    <Col>
                        <Status
                            status={getOnlinePaymentStatusName(selectedTransaction?.status)}
                            type={getOnlinePaymentStatusTagType(selectedTransaction.status)}
                            iconType={getOnlinePaymentStatusIconType(selectedTransaction.status)}
                        />
                    </Col>
                </Row>
            )
        },
        {
            label: translations().date,
            value: selectedTransaction.createdDate,
            render: () => <DateTimeLabel date={selectedTransaction?.createdDate} />
        },
        {
            label: translations().id,
            value: selectedTransaction?.id,
            copyable: true
        },
        {
            label: translations().orderNumber,
            value: selectedTransaction.invoiceId,
            copyable: true
        },
        {
            label: translations().reference,
            value: selectedTransaction.reference,
            copyable: true
        },
        {
            label: translations().transactionType,
            value: rootTranslations().transactionTypes[
                selectedTransaction?.transactionType?.toUpperCase()
            ]
        },
        selectedTransaction.initiatorEmail && {
            label: translations().initiatorEmail,
            value: selectedTransaction?.initiatorEmail,
            copyable: true
        }
    ].filter((item) => item)
})

const summaryDetails = (selectedTransaction: EpayPayment) => {
    return {
        title: translations().summary,
        fields: [
            selectedTransaction.processingTypeName && {
                label: translations().processingTypeName,
                value: selectedTransaction.processingTypeName
            },
            {
                label: translations().shop,
                value: selectedTransaction.shop
            },
            {
                label: translations().description,
                value: selectedTransaction.description
            },
            {
                label: translations().authorizedOn,
                value: `${selectedTransaction.authDate}`,
                render: () => <DateTimeLabel date={selectedTransaction.authDate} />
            },
            selectedTransaction.status === OnlinePaymentStatus.cancel && {
                label: translations().cancelOn,
                value: selectedTransaction.payoutDate,
                render: () => <DateTimeLabel date={selectedTransaction.payoutDate} />
            },
            selectedTransaction.status === OnlinePaymentStatus.charge && {
                label: translations().chargedOn,
                value: selectedTransaction.payoutDate,
                render: () => <DateTimeLabel date={selectedTransaction.payoutDate} />
            },
            selectedTransaction.status === OnlinePaymentStatus.refund && {
                label: translations().refundedOn,
                value: selectedTransaction.payoutDate,
                render: () => <DateTimeLabel date={selectedTransaction.payoutDate} />
            },
            !isEmpty(selectedTransaction.issuer) && {
                label: translations().issuer,
                value: selectedTransaction.issuer
            },
            !isEmpty(selectedTransaction.paymentMethod) && {
                label: translations().card,
                value: selectedTransaction.cardMask,
                render: () => (
                    <CardSchemeLabelWithIcon
                        cardScheme={selectedTransaction.cardType}
                        title={selectedTransaction.cardMask}
                    />
                )
            },
            selectedTransaction.donationAmount && {
                label: translations().donationAmount,
                value: getAmountWithCurrency(
                    selectedTransaction.donationAmount,
                    selectedTransaction.currency
                )
            },
            selectedTransaction.cardExpiryDate &&
                /^\d{1,2}\/\d{2}$/.test(selectedTransaction.cardExpiryDate) && {
                    label: translations().cardExpiryDate,
                    value: selectedTransaction.cardExpiryDate
                },
            selectedTransaction.acquirerResponseCode && {
                label: translations().acquirerResponseCode,
                value: selectedTransaction.acquirerResponseCode
            },
            selectedTransaction.authCode && {
                label: translations().authCode,
                value: selectedTransaction.authCode
            },
            selectedTransaction.resultMessage &&
                selectedTransaction.status === OnlinePaymentStatus.reject && {
                    label: translations().resultMessage,
                    value: selectedTransaction.resultMessage
                },
            selectedTransaction.avsResult && {
                label: translations().avsResult,
                value: selectedTransaction.avsResult
            }
        ].filter((item) => item)
    }
}

const verificationDetails = (selectedTransaction: EpayPayment) => {
    return {
        title: translations().verifications,
        fields: [
            {
                label: translations().secure3D,
                value: selectedTransaction.secure3D,
                render: () =>
                    renderVerificationStatus(
                        selectedTransaction.secure3D
                            ? VerificationStatus.yes
                            : VerificationStatus.no
                    )
            },
            selectedTransaction.avsHouseNumberResult && {
                label: translations().AVSHouseNumberResult,
                value: translations().statuses[selectedTransaction.avsHouseNumberResult],
                render: () => renderVerificationStatus(selectedTransaction.avsHouseNumberResult)
            },
            selectedTransaction.avsPostcodeResult && {
                label: translations().AVSPostcodeResult,
                value: selectedTransaction.avsPostcodeResult,
                render: () => renderVerificationStatus(selectedTransaction.avsPostcodeResult)
            },
            selectedTransaction.cscResult && {
                label: translations().cscResult,
                value: selectedTransaction.cscResult,
                render: () => renderVerificationStatus(selectedTransaction.cscResult)
            },
            Boolean(selectedTransaction.paEnrollment) && {
                label: translations().paEnrollment,
                value: selectedTransaction.paEnrollment,
                render: () =>
                    renderVerificationStatus(
                        getPaEnrollmentStatus(selectedTransaction.paEnrollment)
                    )
            },
            Boolean(selectedTransaction.paAuthentication) && {
                label: translations().paAuthentication,
                value: selectedTransaction.paAuthentication,
                render: () =>
                    renderVerificationStatus(getPaAuthStatus(selectedTransaction.paAuthentication))
            }
        ].filter((item) => item)
    }
}

const payPalDetails = (selectedTransaction: EpayPayment) => {
    return {
        title: translations().payPal.details,
        fields: [
            selectedTransaction.additionalDetails?.paypalOrderStatus && {
                label: translations().payPal.paypalOrderStatus,
                value: selectedTransaction.additionalDetails?.paypalOrderStatus
            },
            selectedTransaction.additionalDetails?.paypalCaptureStatus && {
                label: translations().payPal.paypalCaptureStatus,
                value: selectedTransaction.additionalDetails?.paypalCaptureStatus
            },
            selectedTransaction.additionalDetails?.paypalCaptureStatusReason && {
                label: translations().payPal.paypalCaptureStatusReason,
                value: selectedTransaction.additionalDetails?.paypalCaptureStatusReason
            },
            selectedTransaction.additionalDetails?.paypalSellerProtectionStatus && {
                label: translations().payPal.paypalSellerProtectionStatus,
                value: selectedTransaction.additionalDetails?.paypalSellerProtectionStatus
            },
            selectedTransaction.additionalDetails?.paypalSellerProtectionDisputeCategories && {
                label: translations().payPal.paypalSellerProtectionDisputeCategories,
                value: selectedTransaction.additionalDetails
                    ?.paypalSellerProtectionDisputeCategories
            },
            selectedTransaction.additionalDetails?.paypalPayerName && {
                label: translations().payPal.paypalPayerName,
                value: selectedTransaction.additionalDetails?.paypalPayerName
            },
            selectedTransaction.additionalDetails?.paypalEmail && {
                label: translations().payPal.paypalEmail,
                value: selectedTransaction.additionalDetails?.paypalEmail
            },
            selectedTransaction.additionalDetails?.paypalPayerID && {
                label: translations().payPal.paypalPayerID,
                value: selectedTransaction.additionalDetails?.paypalPayerID
            },
            selectedTransaction.additionalDetails?.paypalPayerCountryCode && {
                label: translations().payPal.paypalPayerCountryCode,
                value: selectedTransaction.additionalDetails?.paypalPayerCountryCode
            }
        ].filter((item) => item)
    }
}

const signifydDetails = (signifydDetails: SignifydDetails) => ({
    title: translations().signifydDetails,
    fields: [
        {
            label: translations().signifydId,
            value: signifydDetails.signifydId,
            copyable: true
        },
        {
            label: translations().decision,
            value: signifydDetails.decision
        },
        {
            label: translations().reason,
            value: signifydDetails.reason
        },
        {
            label: translations().score,
            value: signifydDetails.score
        }
    ]
})

const transactionDetails = (selectedTransaction: EpayPayment) => {
    const details = [
        orderDetails(selectedTransaction),
        verificationDetails(selectedTransaction),
        summaryDetails(selectedTransaction),
        selectedTransaction.paymentMethod === PaymentMethodType.paypal &&
            Boolean(selectedTransaction.additionalDetails) &&
            payPalDetails(selectedTransaction),
        payerDetails(selectedTransaction),
        geolocationDetails(selectedTransaction),
        paymentPageDetails(selectedTransaction)
    ].filter((item) => item)

    if (selectedTransaction.signifydDetails) {
        details.push(signifydDetails(selectedTransaction.signifydDetails))
    }

    return details
}

export const getTransactionDetails = (selectedTransaction) => {
    if (!selectedTransaction?.status) {
        return []
    }

    return transactionDetails(selectedTransaction)
}

const enum ProcessTypeId {
    InitialUCOF = 1,
    UCOF_MIT = 2,
    InitialCOF = 3,
    COF_CIT = 4,
    MailOrder = 5,
    TelephoneOrder = 6,
    InitialRecurring = 7,
    RecurringMIT = 8,
    MailOrderInitialCOF = 9,
    TelephoneOrderInitialCOF = 10,
    MOTORecurringMIT = 11
}

const validProcessingTypeIdsForProcessNewPayment = [
    ProcessTypeId.InitialUCOF,
    ProcessTypeId.InitialCOF,
    ProcessTypeId.InitialRecurring,
    ProcessTypeId.MailOrderInitialCOF,
    ProcessTypeId.TelephoneOrderInitialCOF,
    ProcessTypeId.MOTORecurringMIT
]

export const isProcessNewPaymentActionVisible = (
    processingTypeId: number,
    status: OnlinePaymentStatus,
    paymentMethod: PaymentMethodType
) => {
    return (
        validProcessingTypeIdsForProcessNewPayment.includes(processingTypeId) &&
        ![
            OnlinePaymentStatus.reject,
            OnlinePaymentStatus.failed,
            OnlinePaymentStatus.processing,
            OnlinePaymentStatus.new
        ].includes(status) &&
        [
            PaymentMethodType.card,
            PaymentMethodType.applepay,
            PaymentMethodType.googlepay,
            PaymentMethodType.clicktopay
        ].includes(paymentMethod)
    )
}
