import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Divider, Empty} from 'antd'

import {Card, PageContainer, IOutletsContainerStore} from '@/components'
import {OutletCard} from '@/components/containers'

import {OutletsContainerProps} from './props'
import styles from './OutletsContainer.scss'

export const OutletsContainer: React.FC<OutletsContainerProps> = observer(
    ({injectableIdentifier, containerTitle}) => {
        const {isLoading, onOutletCardClick, outlets} =
            useInjection<IOutletsContainerStore>(injectableIdentifier)

        return (
            <PageContainer title={containerTitle} isLoading={false}>
                <Divider className={styles.divider} />
                {isLoading && <Card loading={isLoading} />}
                {outlets.map((outlet, index) => (
                    <OutletCard
                        key={`${outlet.id}-${index}`}
                        mid={outlet.mid}
                        address={outlet.address}
                        isActive={outlet.isActive}
                        storeName={outlet.storeName}
                        latitude={outlet.latitude}
                        longitude={outlet.longitude}
                        onClick={() => onOutletCardClick(outlet.id)}
                    />
                ))}
                {outlets.length === 0 && !isLoading && <Empty description={false} />}
            </PageContainer>
        )
    }
)
