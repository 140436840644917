import translations from './translations'

translations.add('sl', {
    titles: {
        selfBankAccount: 'Vlastný bankový účet',
        directDebitAccount: 'Účet priameho inkasa',
        settlementsAccount: 'Účet pre zúčtovania'
    },
    labels: {
        outletStatus: 'Stav Prevádzky',
        address: 'Adresa',
        mid: 'MID'
    }
})
