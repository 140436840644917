import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    titles: {
        selfBankAccount: string
        directDebitAccount: string
        settlementsAccount: string
    }
    labels: {
        outletStatus: string
        address: string
        mid: string
    }
}>()
