import {isEmpty} from 'dna-common'
import {subscribe} from 'dna-react-ioc'
import {inject, injectable} from 'inversify'
import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {Profile, ProfileMerchant} from '@/stores/profile/models'
import {getProfile} from '@/stores/profile/services/fetchers'
import {ProfileStoreInterface} from '@/pages/Profile'
import {AuthStoreInterface, AuthStoreSymbol} from '@/stores'
import {LoadingState} from '@/types'
import {storage} from '@/services/storage'
import {setDefaultTimeZone} from '@/utils/moment-utils'
import {
    DossierMerchantInfoStoreInterface,
    DossierMerchantInfoStoreSymbol
} from '@/stores/profile/DossierMerchantInfo'
import {MERCHANT_ID_KEY} from '@/constants/auth-constants'

@injectable()
export class ProfileStore implements ProfileStoreInterface {
    _profileData: Partial<Profile> = {}

    loadingState: LoadingState = LoadingState.IDLE
    timeZone: string = storage.get('timezone') || null

    constructor(@inject(AuthStoreSymbol) authStore: AuthStoreInterface) {
        makeAutoObservable(this)

        if (Boolean(this.timeZone)) {
            setDefaultTimeZone(this.timeZone)
        }

        reaction(
            () => ({
                isAuthenticated: authStore.isAuthenticated,
                email: authStore.email
            }),
            async ({isAuthenticated}) => {
                if (!isAuthenticated) {
                    runInAction(() => (this.loadingState = LoadingState.IDLE))
                    return
                }

                await this.loadProfileData()
            }
        )
    }

    get profileData(): Partial<Profile> {
        if (isEmpty(this._profileData) && this.loadingState === LoadingState.IDLE) {
            this.loadProfileData()
        }

        return this._profileData
    }

    get merchant(): ProfileMerchant {
        const merchantId = storage.get(MERCHANT_ID_KEY)

        return (
            this._profileData?.merchants?.find((merchant) => merchant.merchantId === merchantId) ||
            this.profileData?.merchants?.find((merchant) => merchant.isDefault) ||
            (this.profileData?.merchants && this.profileData?.merchants[0])
        )
    }

    get nickname(): string {
        const {firstName, lastName, email} = this._profileData ?? {}

        if (firstName && lastName) return `${firstName} ${lastName}`
        if (firstName) return firstName
        return email
    }

    get portalGuideViewed(): boolean {
        if (isEmpty(this._profileData) && this.loadingState === LoadingState.IDLE) {
            return false
        }

        if (this._profileData?.merchants && isEmpty(this.merchant)) {
            return !isEmpty(this._profileData?.merchants[0].portalGuideViewedDate)
        }

        return !isEmpty(this.merchant?.portalGuideViewedDate)
    }

    loadProfileData = async () => {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => (this.loadingState = LoadingState.LOADING))

        try {
            const {result, error} = await getProfile()

            if (error) {
                runInAction(() => (this.loadingState = LoadingState.FAILED))
            } else {
                runInAction(() => {
                    this.loadingState = LoadingState.DONE
                    this._profileData = result
                })

                if (!result) return

                if (this.timeZone !== result.timezone) {
                    storage.set('timezone', result.timezone)
                    setDefaultTimeZone(result.timezone)
                }

                if (!storage.get(MERCHANT_ID_KEY)) {
                    const defaultMerchant = result.merchants?.find((merchant) => merchant.isDefault)
                    defaultMerchant && storage.set(MERCHANT_ID_KEY, defaultMerchant.merchantId)
                }

                const dossierMerchantInfoStore = subscribe<DossierMerchantInfoStoreInterface>(
                    DossierMerchantInfoStoreSymbol
                )

                await dossierMerchantInfoStore.loadDossierMerchantData()
            }
        } catch (error) {
            runInAction(() => (this.loadingState = LoadingState.FAILED))
            throw new Error(error.message)
        }
    }
}
