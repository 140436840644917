import React, {PropsWithChildren} from 'react'
import {Spin, Typography} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import {BackButton, Container, PageHeader} from '@/components'
import {RouterStore, RouterStoreSymbol} from '@/router/RouterStore'

import {PageContainerProps} from './props'
import styles from './PageContainer.scss'

export const PageContainer: React.FC<PropsWithChildren<PageContainerProps>> = observer(
    ({children, title, isLoading}) => {
        const routerStore = useInjection<RouterStore>(RouterStoreSymbol)

        return (
            <Spin spinning={isLoading}>
                <PageHeader
                    onBack={null}
                    title={<Typography.Title level={3}>{title}</Typography.Title>}
                    className={styles.header}
                    border={null}
                >
                    <BackButton onClick={() => routerStore.back()} />
                </PageHeader>

                <Container>{children}</Container>
            </Spin>
        )
    }
)
