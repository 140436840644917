import translations from './translations'

translations.add('el', {
    titles: {
        selfBankAccount: 'Δικός σας τραπεζικός λογαριασμός',
        directDebitAccount: 'Λογαριασμός άμεσης χρέωσης',
        settlementsAccount: 'Λογαριασμός διακανονισμών'
    },
    labels: {
        outletStatus: 'Κατάσταση Καταστήματος',
        address: 'Διεύθυνση',
        mid: 'MID'
    }
})
