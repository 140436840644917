import translations from './translations'

translations.add('en', {
    titles: {
        selfBankAccount: 'Self bank account',
        directDebitAccount: 'Direct Debit account',
        settlementsAccount: 'Settlements account '
    },
    labels: {
        outletStatus: 'Outlet Status',
        address: 'Address',
        mid: 'MID'
    }
})
