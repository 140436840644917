import React from 'react'
import {observer} from 'mobx-react'
import {SendbirdProvider} from '@sendbird/uikit-react/SendbirdProvider'

import {CustomizedSendbirdApp} from '../CustomizedSendbirdApp'

import {ChatContentProps} from './props'

const SENDBIRD_CONFIG = {
    uikitOptions: {
        groupChannel: {
            enableSuggestedReplies: true,
            enableFormTypeMessage: true
        }
    }
}

export const ChatContent: React.FC<ChatContentProps> = observer(({store}) => (
    <SendbirdProvider
        appId={_SENDBIRD_APPLICATION_ID_}
        userId={store.publicId}
        uikitOptions={SENDBIRD_CONFIG.uikitOptions}
    >
        <CustomizedSendbirdApp store={store} />
    </SendbirdProvider>
))
