import {form} from 'back-connector'
import config from '~/config'
import {LoginRequest, LoginResponse, UpdateTokenRequest} from '@/stores/auth/models'
import {deleteWithAuth, putWithAuth, post} from '@/api'
import {LogoutRateLimiter, LogoutRateLimiterPayload} from '@/services/logout/LogoutRateLimiter'
import {debounce} from 'lodash'
import {storage} from '@/services/storage'
import {RATE_LIMIT_EXCEEDED} from '@/stores/auth/constants/error-codes'

export const login = (request: LoginRequest) =>
    post<LoginResponse>(
        '/oauth/client/oauth2/token',
        form({
            grant_type: 'password',
            scope: config.auth.user.scope,
            username: request.username,
            password: request.password,
            ...(request.merchantId ? {merchant_id: request.merchantId} : {}),
            ...(request.isDefault ? {isDefault: String(request.isDefault)} : {})
        })
    )

export const updateToken = ({
    grant_type,
    merchantId,
    twoFAcode,
    twoFAFrequencyId,
    twoFAStatusId,
    twoFATypeId
}: UpdateTokenRequest) => {
    const params = {
        grant_type,
        merchant_id: merchantId,
        '2fa_code': twoFAcode,
        '2fa_type_id': twoFATypeId,
        '2fa_status_id': twoFAStatusId,
        '2fa_frequency_id': twoFAFrequencyId
    }

    const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, value]) => value !== undefined)
    )

    return putWithAuth<LoginResponse>('/oauth/oauth2/token', form(filteredParams))
}

export const fetchUserAsDefault = (request: Pick<LoginRequest, 'merchantId' | 'isDefault'>) =>
    putWithAuth<{message}>('/oauth/oauth2/user/default-merchant', request)

export const refreshTokenRequest = (refresh_token: string) =>
    post<LoginResponse>(
        '/oauth/client/oauth2/token',
        form({
            grant_type: 'refresh_token',
            scope: config.auth.user.scope,
            refresh_token: refresh_token
        })
    )

const throttledLogoutRequest = () => {
    const LOGOUT_REQUEST_KEY = 'lastLogoutRequestTimestamp'
    const currentTime = Date.now()
    const lastRequestTime = Number(storage.get(LOGOUT_REQUEST_KEY)) || 0
    const oneSecond = 1000

    // Only allow if 1 second has passed since the last request
    if (currentTime - lastRequestTime >= oneSecond) {
        storage.set(LOGOUT_REQUEST_KEY, currentTime.toString()) // Set new timestamp
        return deleteWithAuth<any>('/oauth/connect/token', true) // Execute request
    } else {
        const error = new Error('Logout request blocked: Only 1 request per second is allowed.');
        (error as any).code = RATE_LIMIT_EXCEEDED
        throw error
    }
}

export const logout = (payload: LogoutRateLimiterPayload) => {
    if (!LogoutRateLimiter.trackAndCheckRateLimit(payload)) {
        throw new Error('Rate limit exceeded for logout request')
    }
    return throttledLogoutRequest()
}
