import translations from './translations'

translations.add('en', {
    labels: {
        address: 'Address',
        mid: 'MID'
    },
    status: {
        active: 'Active',
        closed: 'Closed'
    }
})
