import translations from './translations'

translations.add('ro', {
    companyProfile: 'Profilul Companiei',
    labels: {
        customerId: 'ID Client',
        entityType: 'Tip Entitate',
        registeredNumber: 'Număr Înregistrat',
        legalName: 'Nume Legal',
        registeredAddress: 'Adresă Înregistrată',
        tradingName: 'Nume Comercial',
        tradingAddress: 'Adresă Comercială',
        companyWebsite: 'Website-ul Companiei',
        companyEmailAddress: 'Adresă Email Companie',
        companyPhoneNumber: 'Telefon Companie'
    }
})
