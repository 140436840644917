import translations from './translations'

translations.add('el', {
    companyProfile: 'Εταιρικό Προφίλ',
    labels: {
        customerId: 'Αριθμός Πελάτη',
        entityType: 'Τύπος Οντότητας',
        registeredNumber: 'Αριθμός Καταχώρησης',
        legalName: 'Νόμιμο Όνομα',
        registeredAddress: 'Καταχωρημένη Διεύθυνση',
        tradingName: 'Εμπορική Επωνυμία',
        tradingAddress: 'Εμπορική Διεύθυνση',
        companyWebsite: 'Ιστότοπος Εταιρείας',
        companyEmailAddress: 'Διεύθυνση Email Εταιρείας',
        companyPhoneNumber: 'Τηλέφωνο Εταιρείας'
    }
})
