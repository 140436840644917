import translations from './translations'

translations.add('es', {
    companyProfile: 'Perfil de la Empresa',
    labels: {
        customerId: 'ID de Cliente',
        entityType: 'Tipo de Entidad',
        registeredNumber: 'Número de Registro',
        legalName: 'Nombre Legal',
        registeredAddress: 'Dirección Registrada',
        tradingName: 'Nombre Comercial',
        tradingAddress: 'Dirección Comercial',
        companyWebsite: 'Sitio Web de la Empresa',
        companyEmailAddress: 'Correo Electrónico de la Empresa',
        companyPhoneNumber: 'Teléfono de la Empresa'
    }
})
