import translations from './translations'

translations.add('es', {
    columnHeader: {
        business: 'Negocio',
        outletsNProducts: 'Puntos de venta y Productos',
        other: 'Otro'
    },
    cardTitle: {
        companyProfile: 'Perfil de la empresa',
        bankAccounts: 'Cuentas bancarias',
        posOutlets: 'Puntos de venta POS',
        onlineOutlets: 'Puntos de venta en línea',
        teamManagement: 'Gestión del equipo'
    },
    cardDescription: {
        companyProfile: 'Los datos de su empresa',
        bankAccounts: 'Débito directo y liquidaciones',
        posOutlets: 'Tiendas físicas',
        onlineOutlets: 'Tiendas de comercio electrónico',
        teamManagement: 'Invitaciones de acceso y roles'
    }
})
