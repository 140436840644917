import translations from './translations'

translations.add('es', {
    labels: {
        address: 'Dirección',
        mid: 'MID'
    },
    status: {
        active: 'Activo',
        closed: 'Cerrado'
    }
})
