import React from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'

import {useScreenType} from '@/hooks'
import {SendbirdPanel} from '@/stores'

import {ChannelListComponent} from '../ChannelListComponent'
import {ChannelComponent} from '../ChannelComponent'

import {CustomizedSendbirdAppProps} from './props'
import styles from './CustomizedSendbirdApp.scss'

export const CustomizedSendbirdApp: React.FC<CustomizedSendbirdAppProps> = observer(({store}) => {
    const {panel} = store
    const {isMobile} = useScreenType()

    return (
        <div
            className={classNames(`sendbird-app__wrap ${styles.chatContainer}`, {
                [styles.chatContainerMobile]: isMobile
            })}
        >
            {panel === SendbirdPanel.CHANNEL_LIST && (
                <div className={'sendbird-app__channellist-wrap'}>
                    <ChannelListComponent store={store} />
                </div>
            )}
            {panel === SendbirdPanel.CHANNEL && <ChannelComponent store={store} />}
        </div>
    )
})
