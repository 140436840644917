import React, {useEffect} from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Descriptions, Divider, Typography} from 'antd'

import {useScreenType} from '@/hooks'
import {withTitle} from '@/router/utils'
import {PageContainer} from '@/components'
import {getPermissions, navigateUserAfterLogin} from '@/stores/auth/services'

import {ICompanyProfileStore} from './ICompanyProfileStore'
import translations from './translations'
import styles from './CompanyProfile.scss'

export const CompanyProfileStoreSymbol = Symbol('CompanyProfileStoreSymbol')

const CompanyProfile: React.FC<null> = observer(() => {
    const {companyInfo} = useInjection<ICompanyProfileStore>(CompanyProfileStoreSymbol)
    const {isMobile} = useScreenType()

    useEffect(() => {
        if (!companyInfo) {
            navigateUserAfterLogin(getPermissions())
        }
    }, [companyInfo])

    return (
        <PageContainer title={translations().companyProfile} isLoading={!!!companyInfo}>
            <Divider className={styles.divider} />
            <Descriptions
                className={classNames(styles.descriptionLabel, {
                    [styles.descriptionLabelMobile]: isMobile
                })}
                title=''
                layout={isMobile ? 'vertical' : 'horizontal'}
                column={1}
                colon={false}
            >
                <Descriptions.Item label={translations().labels.customerId}>
                    <Typography.Paragraph className={styles.customerId} copyable>
                        {companyInfo?.dsrId}
                    </Typography.Paragraph>
                </Descriptions.Item>
                <Descriptions.Item label={translations().labels.entityType}>
                    {companyInfo?.businessStructureValue}
                </Descriptions.Item>
                <Descriptions.Item label={translations().labels.registeredNumber}>
                    {companyInfo?.registeredNumber}
                </Descriptions.Item>
                <Descriptions.Item label={translations().labels.legalName}>
                    {companyInfo?.companyName}
                </Descriptions.Item>
                <Descriptions.Item label={translations().labels.registeredAddress}>
                    {companyInfo?.registeredAddress}
                </Descriptions.Item>
            </Descriptions>
            {(companyInfo?.tradingName || companyInfo?.tradingAddress) && (
                <Divider className={styles.divider} />
            )}
            <Descriptions
                className={classNames(styles.descriptionLabel, {
                    [styles.descriptionLabelMobile]: isMobile
                })}
                title=''
                layout={isMobile ? 'vertical' : 'horizontal'}
                column={1}
                colon={false}
            >
                {companyInfo?.tradingName && (
                    <Descriptions.Item label={translations().labels.tradingName}>
                        {companyInfo?.tradingName}
                    </Descriptions.Item>
                )}
                {companyInfo?.tradingAddress && (
                    <Descriptions.Item label={translations().labels.tradingAddress}>
                        {companyInfo?.tradingAddress}
                    </Descriptions.Item>
                )}
            </Descriptions>
            <Divider className={styles.divider} />
            <Descriptions
                className={classNames(styles.descriptionLabel, {
                    [styles.descriptionLabelMobile]: isMobile
                })}
                title=''
                layout={isMobile ? 'vertical' : 'horizontal'}
                column={1}
                colon={false}
            >
                {companyInfo?.companyWebSite && (
                    <Descriptions.Item label={translations().labels.companyWebsite}>
                        <a href={companyInfo?.companyWebSite}>{companyInfo?.companyWebSite}</a>
                    </Descriptions.Item>
                )}
                <Descriptions.Item label={translations().labels.companyEmailAddress}>
                    {companyInfo?.companyEmailAddress}
                </Descriptions.Item>
                <Descriptions.Item label={translations().labels.companyPhoneNumber}>
                    {companyInfo?.companyPhoneNumber}
                </Descriptions.Item>
            </Descriptions>
            <Divider className={styles.divider} />
        </PageContainer>
    )
})

export default withTitle(CompanyProfile, translations().companyProfile)
