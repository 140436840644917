import React from 'react'
import {Button, ButtonProps} from 'antd'
import {observer} from 'mobx-react'
import {ArrowLeftOutlined} from '@ant-design/icons'

import translations from './translations'
import styles from './BackButton.scss'

export const BackButton: React.FC<ButtonProps> = observer(({onClick}) => {
    return (
        <Button
            onClick={onClick}
            type='link'
            className={styles.backButton}
            icon={<ArrowLeftOutlined />}
        >
            {translations().back}
        </Button>
    )
})
