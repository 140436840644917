import translations from './translations'

translations.add('en', {
    labels: {
        bank: 'Bank',
        sortCode: 'Sort-code',
        accountNumber: 'Account number',
        associatedSettlementsOutlets: 'Associated settlements outlets'
    }
})
