import {getWithAuth} from '@/api'
import {TerminalType} from '@/constants'
import {OutletDetailsResponseModel, OutletsResponseModel} from '@/stores/outlets'

export const fetchDossierMerchantOutlets = (type: TerminalType) =>
    getWithAuth<OutletsResponseModel>(
        `/oppapi/v1/merchant-info/stores?${type === TerminalType.ALL ? '' : `type=${type}`}`
    )

export const fetchDossierMerchantOutletDetails = (id: string) =>
    getWithAuth<OutletDetailsResponseModel>(`/oppapi/v1/merchant-info/stores/${id}`)
