export const formatAccountNumberWithDots = (
    accountNumber: string
): {
    firstPart: string
    secondPart: string
} => {
    if (!accountNumber) {
        return {
            firstPart: '',
            secondPart: ''
        }
    }

    const numStr = accountNumber.toString()
    const visiblePart = numStr.slice(-4)
    return {
        firstPart: visiblePart.slice(0, 2),
        secondPart: visiblePart.slice(2)
    }
}
