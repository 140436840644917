import React from 'react'
import {observer} from 'mobx-react'
import {GroupChannelList} from '@sendbird/uikit-react/GroupChannelList'
import {GroupChannelListHeader} from '@sendbird/uikit-react/GroupChannelList/components/GroupChannelListHeader'
import Header from '@sendbird/uikit-react/ui/Header'
import {GroupChannel, GroupChannelListOrder} from '@sendbird/chat/groupChannel'

import {SendbirdPanel} from '@/stores'

import {HeaderIconButton} from '../HeaderIconButton/HeaderIconButton'
import {ChannelListComponentProps} from './props'

const CHANNEL_LIST_QUERY_PARAMS = {
    includeEmpty: true,
    order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    limit: 5
}

export const ChannelListComponent: React.FC<ChannelListComponentProps> = observer(({store}) => {
    const {sb, setOpen, createNewChannel, currentChannelUrl, setCurrentChannelUrl, setPanel} = store

    const handleChannelSelect = (channel: GroupChannel) => {
        setCurrentChannelUrl(channel?.url)
        if (channel) setPanel(SendbirdPanel.CHANNEL)
    }

    const handleCreateChannel = async () => {
        const channel = await createNewChannel()
        setCurrentChannelUrl(channel?.url)
        setPanel(SendbirdPanel.CHANNEL)
    }

    return (
        <GroupChannelList
            selectedChannelUrl={currentChannelUrl}
            channelListQueryParams={CHANNEL_LIST_QUERY_PARAMS}
            onChannelCreated={handleChannelSelect}
            onChannelSelect={handleChannelSelect}
            disableUserProfile
            renderHeader={() => (
                <GroupChannelListHeader
                    renderLeft={() => (
                        <HeaderIconButton type='CLOSE' onClick={() => setOpen(false)} />
                    )}
                    renderMiddle={() => <Header.Title title={sb?.currentUser?.nickname} />}
                    renderRight={() => (
                        <HeaderIconButton type='CREATE' onClick={handleCreateChannel} />
                    )}
                />
            )}
        />
    )
})
