import translations from './translations'

translations.add('es', {
    titles: {
        selfBankAccount: 'Cuenta bancaria propia',
        directDebitAccount: 'Cuenta de débito directo',
        settlementsAccount: 'Cuenta de liquidaciones'
    },
    labels: {
        outletStatus: 'Estado del Punto de Venta',
        address: 'Dirección',
        mid: 'MID'
    }
})
