import React from 'react'
import {observer} from 'mobx-react'

import {withTitle} from '@/router/utils'
import {OutletsContainer} from '@/components'

import translations from './translations'

export const OnlineOutletsStoreSymbol = Symbol('OnlineOutletsStoreSymbol')

const OnlineOutlets: React.FC<null> = observer(() => {
    return (
        <OutletsContainer
            injectableIdentifier={OnlineOutletsStoreSymbol}
            containerTitle={translations().onlineOutlets}
        />
    )
})

export default withTitle(OnlineOutlets, translations().onlineOutlets)
