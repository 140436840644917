import {register} from 'dna-react-ioc'
import {SendbirdChatStore} from '@/stores'
import {SendbirdChatContainerStoreSymbol} from '@/components'
import {ISendbirdChatStore} from '@/components/containers/SendbirdChatContainer/ISendbirdChatStore'

export const sendbirdInitState = () => {
    register<ISendbirdChatStore>(SendbirdChatContainerStoreSymbol)
        .to(SendbirdChatStore)
        .inSingletonScope()
}
