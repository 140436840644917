import translations from './translations'

translations.add('en', {
    columnHeader: {
        business: 'Business',
        outletsNProducts: 'Outlets & Products',
        other: 'Other'
    },
    cardTitle: {
        companyProfile: 'Company profile',
        bankAccounts: 'Bank accounts',
        posOutlets: 'POS outlets',
        onlineOutlets: 'Online outlets',
        teamManagement: 'Team management'
    },
    cardDescription: {
        companyProfile: 'Your company details',
        bankAccounts: 'Direct debit and settlements',
        posOutlets: 'Face to face stores',
        onlineOutlets: 'eCommerce stores',
        teamManagement: 'Access invitations and roles'
    }
})
