import React, {PropsWithChildren} from 'react'
import classNames from 'classnames'

import {useScreenType} from '@/hooks'

import styles from './Container.scss'

export const Container: React.FC<PropsWithChildren<React.ReactNode>> = ({children}) => {
    const {isMobile} = useScreenType()
    return (
        <div className={classNames(styles.container, {[styles.containerMobile]: isMobile})}>
            {children}
        </div>
    )
}
