import translations from './translations'

translations.add('el', {
    labels: {
        bank: 'Τράπεζα',
        sortCode: 'Κωδικός Ταξινόμησης',
        accountNumber: 'Αριθμός Λογαριασμού',
        associatedSettlementsOutlets: 'Συνδεδεμένα Καταστήματα Διακανονισμού'
    }
})
