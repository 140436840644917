import {storage} from '@/services/storage'

export type LogoutRateLimiterPayload = {
    trigger: 'back-to-login' | 'user-logout' | 'session-expiry' | 'refresh-token-failure' | 'other-tab-logout' | 'api-call'
    apiCallPayload?: string
    additionalInfo?: any
}

export class LogoutRateLimiter {
    private static MAX_CALLS = 20
    private static TIME_WINDOW_MS = 10000
    private static STORAGE_KEY = 'rateLimiterCalls'

    private static get calls(): { timestamp: number, payload: any }[] {
        // Retrieve and parse calls from sessionStorage
        const storedCalls = storage.get(this.STORAGE_KEY)
        return storedCalls ? JSON.parse(storedCalls) : []
    }

    private static set calls(callData: { timestamp: number, payload: any }[]) {
        const now = Date.now()
        // Filter out outdated calls before storing them
        const validCalls = callData.filter(call => now - call.timestamp < this.TIME_WINDOW_MS)

        // Store the cleaned-up call data in sessionStorage
        storage.set(this.STORAGE_KEY, JSON.stringify(validCalls))
    }

    static trackAndCheckRateLimit(payload: LogoutRateLimiterPayload): boolean {
        const now = Date.now()
        let calls = this.calls

        // Remove entries older than the time window
        calls = calls.filter(call => now - call.timestamp < this.TIME_WINDOW_MS)

        // Check if rate limit is exceeded
        if (calls.length >= this.MAX_CALLS) {
            return false
        }

        // Record the current call's timestamp and payload, then store it back
        calls.push({ timestamp: now, payload })
        this.calls = calls
        return true
    }
}
