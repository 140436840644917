import React, {useEffect, useState} from 'react'
import classNames from 'classnames'
import {Divider, Form, Input, Modal} from 'antd'
import {error} from 'dna-common'

import {OnlinePaymentStatus} from '@/constants'
import {useScreenType} from '@/hooks'
import {getAmountWithCurrency} from '@/utils'
import {
    getOnlinePaymentStatusIconType,
    getOnlinePaymentStatusName,
    getOnlinePaymentStatusTagType
} from '@/pages/OnlinePayments/services'

import {Button} from '@/components/dumb/Button'
import {Description} from '@/components/dumb/Description'
import {DateTimeLabel} from '@/components/dumb/DateTimeLabel'
import {TitleWithStatus} from '@/components/composite/TitleWithStatus'
import {PaymentMethodOrMaskedCard} from '@/components/composite/PaymentMethodOrMaskedCard'

import translations from './translations'
import {SendOrDownloadReceiptProps} from './props'
import styles from './styles.scss'
import {
    DOWNLOAD_RECEIPT_BUTTON,
    EMAIL_TO_SEND_RECEIPT,
    RECEIPT,
    SEND_RECEIPT
} from '@/constants/playwright-ids'

const {useForm} = Form

export const SendOrDownloadReceipt: React.FC<SendOrDownloadReceiptProps> = (props) => {
    const {email, order, className, sendReceipt, downloadReceipt} = props
    const {
        amount,
        currency,
        status,
        description,
        orderNumber,
        paymentMethod,
        cardMask,
        cardType,
        date
    } = order
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const {isMobile} = useScreenType()
    const [form] = useForm()

    const send = (values) =>
        submitPromise(async () => {
            await form.validateFields()
            close()
            await sendReceipt(values.email)
        })

    const download = () =>
        submitPromise(async () => {
            close()
            await downloadReceipt()
        })

    const submitPromise = async (func: () => Promise<void>) => {
        setIsLoading(true)
        try {
            await func()
        } catch (err) {
            error(err)
        }
        setIsLoading(false)
    }

    const close = () => {
        setIsOpen(false)
        form.resetFields()
    }

    useEffect(() => {
        form.setFieldsValue({
            email
        })
    }, [form, email])

    const renderSendBtn = () => (
        <Button type={'ghost'} onClick={() => form.submit()} data-test-id={SEND_RECEIPT}>
            {translations().buttons.send}
        </Button>
    )

    const renderForm = () => (
        <Form
            form={form}
            layout='vertical'
            onFinish={send}
            hideRequiredMark={true}
            initialValues={{email}}
        >
            <Form.Item
                name='email'
                label={translations().labels.email}
                rules={[
                    {
                        required: true,
                        message: translations().errors.emailRequired
                    },
                    {
                        type: 'email',
                        message: translations().errors.emailInvalid
                    }
                ]}
            >
                <Input
                    placeholder={translations().labels.emailPlaceholder}
                    data-test-id={EMAIL_TO_SEND_RECEIPT}
                />
            </Form.Item>

            {isMobile && renderSendBtn()}
        </Form>
    )

    const renderDetails = () => (
        <div>
            <Description
                labelCol={12}
                dataSource={[
                    {
                        label: translations().labels.date,
                        render: () => <DateTimeLabel date={date} />
                    },
                    {
                        label: translations().labels.paymentMethod,
                        render: () => (
                            <PaymentMethodOrMaskedCard
                                paymentMethod={paymentMethod}
                                cardMask={cardMask}
                                cardScheme={cardType}
                            />
                        )
                    },
                    {label: translations().labels.orderNumber, value: orderNumber}
                ]}
            />
        </div>
    )

    return (
        <>
            {status !== OnlinePaymentStatus.auth && amount > 0 && (
                <Button
                    className={classNames(className, 'nowrap')}
                    loading={isLoading}
                    onClick={() => setIsOpen(true)}
                    data-test-id={RECEIPT}
                >
                    {translations().buttons.receipt}
                </Button>
            )}

            <Modal
                title={translations().title}
                className={styles.modal}
                open={isOpen}
                onCancel={close}
                footer={
                    <>
                        <Button
                            type={'primary'}
                            onClick={download}
                            data-test-id={DOWNLOAD_RECEIPT_BUTTON}
                        >
                            {translations().buttons.downloadReceipt}
                        </Button>

                        {!isMobile && renderSendBtn()}
                    </>
                }
                width={700}
            >
                <TitleWithStatus
                    subtitle={description}
                    statusProps={{
                        status: getOnlinePaymentStatusName(status as OnlinePaymentStatus),
                        type: getOnlinePaymentStatusTagType(status as OnlinePaymentStatus),
                        iconType: getOnlinePaymentStatusIconType(status as OnlinePaymentStatus)
                    }}
                >
                    {getAmountWithCurrency(amount, currency)}
                </TitleWithStatus>

                <Divider />

                {isMobile ? (
                    <>
                        {renderForm()}

                        <Divider />

                        {renderDetails()}
                    </>
                ) : (
                    <div className={styles.row}>
                        {renderDetails()}

                        {renderForm()}
                    </div>
                )}
            </Modal>
        </>
    )
}
